@import 'src/styles/colors.scss';

.button-menu-default {
  display: inline-block;
  padding-top: 0;
  position: relative;

  .button-default.ant-btn-default {
    outline: none;
    padding: 10px 15px;
    svg {
      display: block;
      margin: 0;
    }
    &:focus {
      background-color: $accent_30;
      border-color: $accent_80;
      color: $accent_80;
      svg {
        fill: $accent_80;
      }
    }
  }

  .menu-list {
    position: absolute;
    display: none;
    background: $white;
    box-shadow: $main_shadow;
    border-radius: 6px;
    list-style-type: none;
    padding: 8px 0;
    margin: 0;
    min-width: 188px;
    right: 0;
    top: 40px;
    z-index: 1;

    &:empty,
    &:not(.visible) {
      display: none;
    }

    li {
      padding: 8px 16px;
      font-size: 14px;
      line-height: 20px;
      color: $main;
      cursor: pointer;

      &.red {
        color: $error;
      }

      &:hover {
        background-color: $disabled_bg;
      }
    }
  }

  &:focus-within .menu-list.visible {
    display: block;
  }

  &:has(.button-default[disabled]) .menu-list,
  &:has(.menu-list:empty) {
    display: none;
  }

  &.fixed {
    .menu-list {
      margin-top: 4px;
      position: fixed;
      transform: translateX(-100%);
    }
  }
}