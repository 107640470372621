@import "../../styles/colors.scss";

.dropdown-default {
 position: relative;
  ul {
    margin: 0;
    padding: 8px 0;
    background-color: $white;
    transition: background-color 0.3s ease-out;
    border-radius: $border_radius;
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
    list-style-type: none;
    box-shadow: $box_shadow_level_4;
    li {
      padding: 8px 16px;
      border-radius: $border_radius;
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      line-height: 20px;
      color: $main;
      cursor: pointer;
      &:hover {
        background-color: $disabled_bg;
      }
    }
  }
  &.open ul {
    display: block;
  }
}