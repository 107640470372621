@import "src/styles/colors";
@import "src/styles/breakpoints";

.opening-loading {
  padding: 24px;
}

.decline-opening-popup {
  .icon-close {
    cursor: pointer;
    padding: 7px;
    svg {
      display: block;
    }
  }
  .popup_subtitle {
    margin-bottom: 24px;
    padding: 12px 24px 24px;
    width: 100%;
    font-size: 15px;
    line-height: 20px;
    color: #474747;
    border-bottom: 1px solid #E6E8EB;
  }
  .modal-body {
    padding: 0 24px;
  }
  .wrapper-popup_footer {
    margin-bottom: 24px;
  }
}

.openings-filter {
  margin: 24px 0 24px;

  &__item-group {
    display: flex;
    align-items: center;

    &__title {
      color: $blackblue;

      span {
        color: $lightgray;
      }
    }

    &.filter-section {
      margin-top: 16px;
    }

    &__info {
      margin-left: 8px;
    }

    .status {
      margin-right: 16px;
    }

    .only-my-openings {
      margin: 0 0 -22px 16px;
    }

    .sorting {
      margin-bottom: -24px;
      padding: 0;
      border: 0;
      outline: none;
      background: none;
      white-space: nowrap;
      cursor: pointer;
      display: flex;
      align-items: center;

      svg {
        margin-left: 4px;
        &.asc #Ascending {
          fill: $main;
        }
        &.desc #Descending {
          fill: $main;
        }
      }

      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: $main;
    }

    .level {
      margin-right: auto;
    }

    .select-default {
      margin-bottom: 0;
      width: 312px;
    }

    .extra-filters {
      display: flex;
    }
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
    &__item-group.filter-section {
      position: relative;

      .select-default {
        flex: 1;

        &.level {
          margin-right: 0;
        }
      }

      .extra-filters {
        position: absolute;
        top: -40px;
        right: 0;
      }
    }
  }

  @media only screen and (max-width: $screen-mobile-max) {
    margin: 16px 0 0;
    padding: 0 16px 16px;
    flex-direction: column;
    border-bottom: 1px solid $border;

    &__item-group {
      margin-bottom: 12px;

      .status {
        margin-bottom: 12px;
      }

      .status,
      .level {
        width: 100%;
      }

      &.filter-section {
        margin: 48px 0 0;
        position: relative;
        flex-direction: column;
        align-items: flex-start;

        .extra-filters {
          position: absolute;
          top: -36px;
          right: 0;
          width: 100%;
          justify-content: space-between;
          flex-direction: row-reverse;

          .only-my-openings {
            margin-left: 0;
          }
        }
      }
    }
  }
}

.popup-delete-opening {

  &__title {
    margin-bottom: 4px;
    color: $main;
  }

  &__subtitle {
    margin-bottom: 16px;
    color: $lightgray;
  }

  &__text {
    color: $black;
  }
}

.openings-list {
  &__opening {
    margin-bottom: 12px;

    &.highlight {
      outline: auto $accent_80;
    }
  }

  .empty-opening {
    height: 418px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__image {
      height: 124px;
      width: 124px;
    }
    &__title {
      margin-top: 40px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #393F4A;
    }
    &__text {
      margin-top: 8px;
    }
    &__add-opening {
      margin-top: 20px;
    }
  }
}