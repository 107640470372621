@import "src/styles/colors";

.popup-create-offer {
  width: 520px;
  max-width: 100%;

  .months {
    margin-top: 30px;
  }
}
