@import "src/styles/colors.scss";

.select-default {
  width: 100%;
  margin-bottom: 16px;

  .typography-label {
    margin-bottom: 8px;
    color: $main;

    .required {
      color: $error;
    }
    .optional {
      color: $lightgray;
    }
  }

  .ant-select {
    width: 100%;

    &-selection-overflow-item-suffix input {
      height: 28px;
    }
    .ant-select-selector {
      height: 36px;
      padding: 3px 10px;
    }

    &:hover .ant-select-selector {
      border-color: $disabled_text;
    }

    .ant-select-arrow path {
      fill: $placeholder;
      transition: 0.3s;
    }

    &-focused {
      border-color: #FF803C;

      .ant-select-arrow path {
        fill: $disabled_text;
      }
    }
    &-open {
      .ant-select-selection-item {
        color: $main;
      }
      .ant-select-selector {
        border-color: $accent_60;
      }
    }


    &-disabled {
      .ant-select {
        &-selector {
          background-color: $disabled_bg !important;
        }
        &-selection-item {
          color: $lightgray;
        }
        &-arrow path {
          fill: $disabled_text;
        }
      }
    }

    &-open .ant-select-arrow {
      transform: rotate(180deg);
      transform-origin: center center;
    }

    &-selector {
      box-shadow: none !important;
    }
    &-clear {
      opacity: 1;
      width: 32px;
      height: 36px;
      right: 25px;
      top: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;

      &:hover {
        circle {
          fill: $stroke;
        }
        path {
          fill: $placeholder;
        }
      }
    }
    &-arrow {
      right: 10px;
      transition: transform .3s;
    }

    &-multiple .ant-select-selector {
      padding: 0 65px 0 10px;
    }

    &-show-search,
    &-multiple {
      .ant-select-selector {
        cursor: pointer !important;
      }
      input {
        cursor: pointer !important;
      }
      &.ant-select-focused .ant-select-selector {
        cursor: text !important;
        input {
          cursor: text !important;
        }
      }
    }
  }
  &__dropdown {
    padding: 8px 0;
    box-shadow: 0 0.4px 0.8px rgba(80, 89, 102, 0.1), 0 3px 6px rgba(80, 89, 102, 0.2);
    border-radius: 8px;

    .ant-select-item-group {
      min-height: 24px;
      padding: 4px 16px;
      color: $placeholder;
      font-size: 12px;
      line-height: 16px;
    }

    .ant-select-item-option {
      position: relative;
      align-items: center;

      &:hover {
        background-color: $disabled_bg;
      }

      &-content {
        padding: 8px 16px;
        max-width: calc(100% - 40px);
        font-family: 'Open Sans', sans-serif;
        overflow-x: hidden;
        text-overflow: ellipsis;
      }
      &-state {
        display: flex;
        align-items: center;
        margin-left: 16px;
        margin-right: 12px;
      }
    }

    .ant-select-item-option {
      padding: 6px 12px 6px 16px;
      &-content {
        padding: 0;
      }
      &-state {
        margin: 0 0 0 16px;
      }
    }

    &_multiple {
      .ant-select-item {
        padding: 0;

        &-option {
          &:after {
            pointer-events: none;
            content: '';
            position: absolute;
            top: 6px;
            left: 12px;
            height: 24px;
            width: 24px;
            display: block;
            background-image: url("../../../src/dist/icons/checkbox.svg");
          }

          &-selected:after {
            background-image: url("../../../src/dist/icons/checkbox-selected.svg");
          }

          &-content {
            padding-left: 44px;
            max-width: 100%;
          }
          &-state {
            display: none;
          }
        }
      }
    }
  }

  &__tag {
    display: block;
    margin-right: 4px;
    padding: 2px 28px 2px 8px;
    background-color: $accent_70;
    border: none;
    border-radius: 12px;
    color: $white;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 20px;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    .ant-tag-close-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 4px;
      margin-left: 8px;
      svg {
        circle {
          transition: fill .3s;
          fill: $accent_90;
        }
        path {
          transition: fill .3s;
          fill: $white;
        }
      }
      &:hover {
        circle {
          fill: $white;
        }
        path {
          fill: $accent_70;
        }
      }
      &:active {
        circle {
          fill: $accent_20;
        }
        path {
          fill: $accent_70;
        }
      }
    }
    &_extra {
      padding: 2px 8px;
      text-align: center;
      min-width: 35px;
      background-color: $accent_90;
      margin: 0;
    }
    &_disabled {
      padding: 2px 8px;
      background-color: $border;
      color: $main;
    }
  }
  .ant-select-selection-item {
    padding: 0;
    border: none;
    &-content {
      margin: 0;
    }
  }
  .ant-select-selection-search {
    margin-left: 0;
    color: $main;
  }
  &-placeholder {
    color: $lightgray;
  }

  .ant-form-item-explain-error {
    margin-top: 4px;
    font-size: 12px;
    line-height: 16px;
  }

  &.single-select-tags {
    .select-default__tag {
      margin: 0;
      padding: 0;
      width: 0;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: visible;
      background-color: transparent;
      pointer-events: none;
      border-radius: 0;
      color: $main;
      transition: none;
      .ant-tag-close-icon {
        display: none;
      }
    }

    &:has(.ant-select-selection-search input:not([value=""])) .select-default__tag {
      color: transparent;
    }
    .ant-select-selection-overflow {
      flex-wrap: nowrap;
      overflow: hidden;
    }
  }
}
