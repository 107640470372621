@import "colors";
@import "breakpoints";

.wrapper-content {
  display: flex;
  max-width: 1200px;
  padding: 80px 28px 0;
  margin: 0 auto;


  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
    padding: 146px 32px 32px;
  }

  @media only screen and (max-width: $screen-mobile-max) {
    padding: 102px 0 8px;
  }
}

.separator {
  width: calc(100% + 24px);
  transform: translateX(12px);
  height: 1px;
  background: #E6E8EB;
  margin: 10px 0 30px;

  &.networks-wrapper-separator {
    margin-top: 0;
    transform: none;
  }
}
