@import "../../styles/colors";
@import "../../styles/breakpoints";

.analytics-dashboard {
  display: block;
  padding-bottom: 16px;

  .ats-select-wrapper, .wrapper-custom-date-picker {
    margin-bottom: 0;
    &_customInput {
      padding: 0;
      &_value {
        padding: 0;
      }
    }
  }
  ul {
    margin: 0;
    padding: 0;
  }

  .total-candidate {
    display: flex;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 20px;

    svg {
      width: 46px;
      height: 46px;
    }

    .number {
      font-size: 64px;
      font-weight: 400;
      margin-left: 16px;
      margin-right: 8px;
    }

    span {
      color: #707E8B;
      font-weight: 500;
      font-size: 18px;
    }
  }

  .line {
    width: 100%;
    height: 1px;
    background: #DADCE0;
  }

  .ats-control-label {
    margin-bottom: 12px;
    font-size: 15px;
  }

  .wrapperCheckbox {
    padding-left: 36px !important;
  }

  .react-datepicker {
    padding: 0 20px 30px 20px;
  }

  .ats-tabs_underline {
    z-index: unset;
  }

  .ats-select--is-disabled {
    .ats-select__multi-value {
      background-color: hsl(0,0%,90%);
      color: #B3BBC4;

      .ats-select__multi-value__label {
        color: #B3BBC4;
      }
    }
  }

  .title {
    font-weight: 600;
    font-size: 18px;
    color: $black;
  }

  .shadow {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    background-color: $white;
    padding: 25px 24px 32px;
  }

  .candidate {
    display: flex;
    margin-top: 32px;
    justify-content: space-between;

    .shadow {
      margin-top: 0;
    }
  }

  .options-wrapper {
    max-height: 403px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .export-excel-popup {
    &-content {
      .title {
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: $black;
      }
      .body-text {
        white-space: pre-wrap;
        margin-top: 16px;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: $main;
      }
      .confirmation {
        margin: 14px auto 0;
        display: block;
      }
    }
    &__footer {
      margin-top: 32px;
      display: flex;
      justify-content: center;
      .button-default {
        width: 141px;
        &.secondary {
          margin-right: 20px;
        }
      }
    }
  }

  .analytics-dashboard-table {
    margin-top: 32px;
    margin-bottom: 10px;
    border: 1px solid $border;
    box-shadow: none;

    &.pagination {
      margin: 0;
      padding: 12px;
      display: flex;

      span {
        font-size: 15px;
        line-height: 24px;
        color: $main;
      }
    }
    &__loading {
      margin: 0 24px 16px;
    }

    .export-excel {
      padding: 8px 16px;
      svg path {
        fill: $accent_70;
      }
      &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 24px;
        > div {
          display: flex;
        }
      }
      &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: $black;

        &_extra {
          color: #717C8C;
          &:empty {
            display: none;
          }

          &-wrapper {
            display: flex;
            flex-direction: column;
          }
        }
      }
      &__subtitle {
        margin-left: 8px;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: $lightgray;
      }
    }
    table {
      border-right: 0;
      border-bottom: 0;
      border-left: 0;
    }
    .vector {
      cursor: pointer;

      &.rotate {
        transform: rotate(180deg);
      }
      &.disable {
        user-select: none;
        cursor: no-drop;
        path {
          stroke: $stroke;
        }
      }
    }
    .react-loading-skeleton {
      display: block;
    }
  }
}
////////////////////////////////////////////////
.analytics-dashboard-table .table-default {
  overflow: auto;
  &__header {
    display: flex;
    align-items: stretch;
  }
  &__header-cell {
    padding: 8px 12px;
    background-color: $disabled_bg;
    box-shadow: inset -1px -1px 0px $border;

    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $main;

    svg {
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: $border;
      }

      &.asc #Ascending {
        fill: $main;
      }
      &.desc #Descending {
        fill: $main;
      }
    }
  }
  .cell-default {
    padding: 10px 12px;
    background-color: $white;
    box-shadow: inset 0 -1px 0px $border;
  }
  .text-body {
    display: block;
    &.ellipsis, .ellipsis {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  &::-webkit-scrollbar {
    background-color: $disabled_bg;
    height: 20px !important;
    border: 6px solid white !important;
    background-clip: padding-box;
    border-radius: 9999px !important;
  }
  &::-webkit-scrollbar-thumb {
    border: 6px solid transparent !important;
    background-clip: padding-box;
    border-radius: 9999px !important;
  }

  &__footer {
    .items-per-page {
      display: flex;
      align-items: center;

      .select-small-default {
        margin-left: 8px;
      }
    }

    .page-select {
      display: flex;
      align-items: center;

      .text-body {
        margin: 0 24px 0 8px;
      }
    }
  }
}

/* Web/body */
.text-body {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: $main;
}

$status-bg-blue: #E3EDFD;
$status-color-blue: #225FBF;

$status-bg-green: #D3F6DC;
$status-color-green: #088B2D;

$status-bg-yellow: #FFEBBB;

$status-bg-red: #FDE3E3;
$status-color-red: #B74242;

.status-default {
  height: min-content;
  width: max-content;
  padding: 2px 6px 4px;
  border-radius: $border_radius;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  &.gray {
    background-color: $border;
    color: $darkgray;
  }
  &.blue {
    background-color: $status-bg-blue;
    color: $status-color-blue;
  }
  &.green {
    background-color: $status-bg-green;
    color: $status-color-green;
  }
  &.yellow {
    background-color: $status-bg-yellow;
    color: $main;
  }
  &.red {
    background-color: $status-bg-red;
    color: $status-color-red;
  }
  &.black {
    background-color: $blackblue;
    color: $white;
  }
}

.input-table {
  width: 40px;
  height: 28px;
  padding: 4px 10px;
}

.period-role {
  height: auto;
  margin-bottom: 12px;
  padding: 24px 24px 0;
  width: 100%;

  .typography-h1 {
    margin-bottom: 24px;
  }

  .role-filter {
    width: 100%;
    height: auto;

    .ats-tabs_tab-role {
      padding: 8px 16px;

      .title {
        margin: 0;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: $lightgray;
      }

      &.ats-tabs_tab_active {
        .title {
          color: $main;
        }
      }
    }

    .ats-tabs_underline {
      height: 2px;
      border-radius: 1px;
    }
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {}

  @media only screen and (max-width: $screen-mobile-max) {}
}