@import '../../styles/colors.scss';

.chips-default {
  display: inline-block;
  background-color: $accent_20;
  border-radius: 100px;
  color: $main;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  padding: 6px 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  transition: 0.3s;

  &:focus {
    background-color: $accent_30;
    color: $main;
  }
  &:hover {
    background-color: $accent_20;
    color: $accent_60;
  }
  &:active {
    background-color: $accent_30;
    color: $accent_60;
  }
  &.disabled {
    background-color: $disabled_bg;
    color: $disabled_text;
  }
}
