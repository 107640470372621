@import "../../styles/colors";

.select-small-default {
  display: flex;
  align-items: center;

  ul {
    min-width: 88px;
    top: unset;
    bottom: unset;
    right: unset;
    left: unset;
    padding: 8px 0;
    height: fit-content;
    li {
      padding: 8px 40px 8px 16px;
      position: relative;
      white-space: nowrap;

      svg {
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
      }
    }
  }

  &.bottom-left ul {
    top: 100%;
    left: 0;
  }
  &.bottom-right ul {
    top: 100%;
    right: 0;
  }
  &.top-left ul {
    bottom: 100%;
    left: 0;
  }
  &.top-right ul {
    bottom: 100%;
    right: 0;
  }

  &__trigger {
    padding-left: 4px;
    border-radius: $border_radius;
    display: inline-flex !important;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: $disabled_bg;
    }

    .typography-text {
      text-transform: uppercase;
      white-space: nowrap;
      font-size: 12px;
    }
  }
  &__arrow {
    margin-left: 4px;
    transition: transform 0.1s;
  }

  .dropdown-default.open &__trigger {
    background-color: $border;
  }
  .dropdown-default.open &__arrow {
    transform: rotate(-180deg);
  }
}