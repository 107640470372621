@import "src/styles/colors";
@import "src/styles/breakpoints";

.edit-approvers-popup {
  .edit-approvers__input {
    .ant-select-arrow {
      display: none;
    }
    .ant-select-selection-placeholder {
      right: unset;
    }
  }

  &__assign-to-me {
    transform: translateY(-12px);
  }

  .edit-approvers__list {
    max-height: 75vh;
    overflow: auto;
    .approver {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;

      &__info {
        max-width: calc(100% - 48px);
        .user-default {
          width: auto;
        }
      }

      &__extra {
        display: flex;
        align-items: center;
        svg {
          margin-left: 8px;
          height: 16px;
          width: 16px;
          border-radius: $border_radius;
          cursor: pointer;
          &:hover {
            background: $border;
          }
        }
      }

      &:first-child {
        margin-top: 0;
      }

      .optional-tag {
        margin-left: 8px;
        color: $lightgray;
        font-weight: 400;
      }
    }
  }
}