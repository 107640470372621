@import "../../styles/colors";
@import "../../styles/breakpoints";

.header-block {
  height: 68px;
  width: 100%;
  background: $white;
  box-shadow: $box_shadow_header, inset 0 -4px #F5F7FA;
  position: fixed;
  top: 0;
  z-index: 10;
  &__content {
    height: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  &__tabs {
    height: 100%;

    .ant-tabs-ink-bar {
      height: 4px !important;
      border-radius: 2px;
    }
    .ant-tabs-tab {
      box-shadow: none;
    }
  }

  &__logo svg {
    display: block;
  }

  &__logout {
    display: flex;
    justify-content: flex-end;

    .logout {
      height: 32px;
      width: 86px;
      margin: auto;
      cursor: pointer;
      border-radius: 50px;
      background-color: $accent_20;
      color: $accent_70;
      border: 2px solid $accent_20;
      text-transform: uppercase;
      letter-spacing: 0.01em;
      transition: all .3s;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;

      &:hover {
        background-color: $accent_70;
        border-color: $accent_70;
        color: $white;
      }

      &:focus {
        background-color: $accent_70;
        border-color: $accent_90;
        color: $white;
      }

      &:active {
        background-color: $accent_80;
        border-color: $accent_80;
        color: $white;
      }
    }
    .logout-mobile {
      display: none;
    }
    .change-role {
      margin-right: 40px;
      display: flex;
      align-items: center;
      &__trigger {
        display: flex;
        align-items: center;
        font-size: 12px;
        cursor: pointer;
        transition: color .3s;
        &-icon {
          margin-left: 4px;
          transition: transform .3s;
          path {
            fill: $lightgray;
          }

          &-mobile {
            display: none;
          }
        }
        &:hover {
          color: $accent_80;
          .change-role__trigger-icon path {
            fill: $accent_80;
          }
        }
      }
      &__menu {
        ul {
          top: 34px;
          width: max-content;
          max-width: 340px;

          li {
            position: relative;
            padding-right: 52px;

            .role-info {
              margin-top: 2px;
              font-size: 12px;
              line-height: 16px;
              color: $placeholder;
            }

            .role-checkmark {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 12px;
            }
          }
        }
        &.open {
          .change-role__trigger {
            color: $accent_70;
            &-icon {
              transform: rotate(180deg);
              path {
                fill: $accent_70;
              }
            }
          }
        }
      }
    }
  }

  &__menu  {
    display: none;
    &.dropdown-default ul {
      width: 174px;
      top: 45px;
      right: -32px;
      li {
        text-transform: capitalize;
      }
    }
    &_burger {
      display: none;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
    height: 136px;
    padding-bottom: 68px;

    &__content {
      position: relative;
      padding: 0 32px;
    }

    &__tabs {
      position: absolute;
      top: 100%;
    }
  }

  @media only screen and (max-width: $screen-mobile-max) {
    height: 92px;
    padding-bottom: 36px;
    box-shadow: inset 0 -1px $disabled_text;

    &__content {
      position: relative;
      padding: 0 16px;
    }

    &__logo svg {
      width: 180px;
    }

    &__tabs {
      position: absolute;
      top: 100%;
      left: 0;
      height: 36px;

      .ant-tabs-ink-bar {
        height: 2px !important;
      }
    }

    &__logout {
      .change-role {
        margin: 0;
      }
      .change-role__trigger {
        cursor: default;
      }
      .change-role__trigger-text,
      .change-role__trigger-icon,
      .logout {
        display: none;
      }
      .change-role__trigger-icon-mobile,
      .logout-mobile {
        margin-left: 16px;
        width: 24px;
        height: 24px;
        display: block;
        cursor: pointer;
      }
    }
  }
}
