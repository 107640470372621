@import "../../styles/colors";
@import "../../styles/breakpoints";

.approvers-list {
  background-color: $white;

  &__header {
    margin-bottom: 24px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .title {
      margin: 0;
      display: flex;
      align-items: center;
      gap: 6px;

      .typography-h2 {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: $main;
      }
    }
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 12px 32px;

    .user-default {
      width: 336px;
    }
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
  }
  @media only screen and (max-width: $screen-mobile-max) {

    &__content {
      .user-default {
        width: 100%;
      }
    }
  }
}
