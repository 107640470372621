@import '../../styles/colors.scss';

.ats-content-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  width: 28px;
  height: 28px;
  margin: 0 -6px;
  border-radius: 14px;
  outline: none;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  transition: background-color 0.3s ease-out;

  &:hover {
    background-color: $whitegray;
  }

  &:focus {
    visibility: visible;
    background-color: $white;

    ul {
      display: block;
      background: $whitegray;
      min-width: 128px;
      width: max-content;
      padding: 12px 8px;
      margin: 0;
      box-shadow: $box_shadow_card;
      border-radius: 6px;
      list-style-type: none;
      z-index: 1;
      right: -4px;
      top: -2px;

      li {
        text-align: left;
        //margin-bottom: 15px;
        padding: 10px 15px;
        font-size: 14px;
        font-family: 'Open Sans', sans-serif;
        letter-spacing: 0.25px;
        color: $black;
        transition: all .2s;

        //&:last-child {
        //  margin-bottom: 0;
        //}

        &:hover {
          cursor: pointer;
          background-color: $disabled_bg;
          transition: all .3s ease-out !important;
        }

        &.active {
          color: $accent_70;
        }
      }
    }
  }

  .icon-menu {
    display: flex;
  }

  ul {
    position: absolute;
    display: none
  }
}
