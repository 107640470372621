@import 'src/styles/colors.scss';
@import 'src/styles/breakpoints.scss';

.tabs-default {
  .ant-tabs-tab {
    margin: 0;
    padding: 8px 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $lightgray;
    box-shadow: 0px -1px 0px $disabled_text inset;

    &:hover {
      background-color: $disabled_bg;
      color: $main;
    }
    &:focus-within {
      background-color: $accent_20;
      .ant-tabs-tab-btn {
        color: $main;
      }
    }
    &:hover,
    &:focus,
    &-active {
      .ant-tabs-tab-btn {
        color: $main;
        &:focus {
          color: $main;
        }
      }
    }
  }

  .ant-tabs-nav {
    margin: 0;
    height: 100%;

    &, &-wrap {
      &:before, &:after {
        display: none;
      }
    }

    &-operations {
      display: none !important;
    }
  }
  .ant-tabs-ink-bar {
    border-radius: 1px;
  }
  .ant-tabs-content-holder {
    display: none;
  }

  @media only screen and (max-width: $screen-mobile-max) {
    width: 100%;
    height: 36px;
    overflow: auto;

    .ant-tabs-ink-bar {
      height: 2px !important;
    }
    .ant-tabs-nav-list {
      width: 100%;
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .ant-tabs-tab-btn {
        margin: 0 auto;
      }
    }
    .ant-tabs-nav-wrap {
      width: 100%;
    }

    .ant-tabs-tab {
      flex: 1 1 auto;
      justify-content: center;
    }
  }
}