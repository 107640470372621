@import "src/styles/colors";
@import "src/styles/breakpoints";
@import "src/styles/mixin";

.dashboard-interviewer {
  width: 100%;
  max-width: 1144px;
  padding: 84px 0 32px;
  margin: 0 auto;

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
    padding: 168px 32px 16px;
  }

  @media only screen and (max-width: $screen-mobile-max) {
    padding: 108px 0 8px;
  }
}