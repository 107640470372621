@import "../../../styles/colors";

.widget-rejection-reasons {
  margin: 0;
  width: 752px;
  padding: 24px 112px 32px 24px;

  .typography-h2 {
    margin-bottom: 24px;
  }

  .select-default {
    width: 260px;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    ul {
      list-style: none;
      li {
        --color: attr(content color);
        position: relative;
        padding-left: 28px;
        .indicator {
          content: " ";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          background-color: red;
          width: 20px;
          height: 20px;
          border-radius: 4px;
        }

        &:not(:first-child) {
          margin-top: 8px;
        }
      }
    }
  }

  .right-block {
    width: 232px;
    height: 232px;

    &__empty {
      width: 232px;
      height: 232px;
    }

    .doughnut {
      position: relative;

      &__info {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        font-size: 14px;
        line-height: 16px;
        color: $placeholder;

        span {
          margin-bottom: 4px;
          display: block;
          font-weight: 500;
          font-size: 40px;
          line-height: 40px;
          color: $accent_90;
        }
      }

      &__tooltip {
        position: absolute;
        pointer-events: none;
        padding: 16px;
        background: $white;
        box-shadow: $box_shadow_level_8;
        border-radius: 8px;
        top: -125px;
        left: 10px;

        &:empty {
          display: none;
        }

        .title {
          margin: 0 0 16px;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: $main;
          text-align: center;
          white-space: nowrap;
        }

        > div > div {
          margin-top: 8px;
          display: flex;
          justify-content: space-between;
          span:last-child {
            margin-left: 12px;
            font-weight: 600;
          }
        }
      }
    }
  }
}