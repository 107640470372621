@import "src/styles/colors";
@import "src/styles/breakpoints";

.popup-default {
  margin: auto;
  padding-bottom: 0;
  max-width: 434px;
  top: 0;
  .ant-modal {
    &-content {
      border-radius: 8px;
      max-height: 95vh;
      display: flex;
      flex-direction: column;

      .user-info {
        margin-bottom: 24px;

        .typography:empty {
          display: none;
        }

        &__title {
          color: $main;
          font-weight: 600;
        }
        &__subtitle {
          margin-top: 4px;
          color: $lightgray;
        }

        > a {
          position: absolute;
          top: 6px;
          right: 20px;
        }
      }
    }
    &-header {
      padding: 24px 78px 16px 32px;
      border-radius: 8px 8px 0 0;
    }
    &-title {
      font-weight: 400;
      font-size: 22px;
      line-height: 26px;
    }
    &-body {
      padding: 8px 26px 8px 32px;
      flex: 1;
      max-height: 65vh;
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      line-height: 20px;
      color: $main;
      position: relative;
      overflow: hidden scroll;

      .datepicker-default {
        width: 100%;
      }
    }
    &-close {
      top: 24px;
      right: 32px;
      height: 24px;
      width: 24px;
      &-x {
        height: 100%;
        width: 100%;
      }
      svg {
        display: block;
        path {
          stroke: $placeholder;
        }
      }
    }
    &-footer {
      border-radius: 0 0 8px 8px;
    }
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    .ant-btn + .ant-btn:not(.ant-dropdown-trigger),
    a + .ant-btn:not(.ant-dropdown-trigger) {
      margin-left: 16px;
    }
  }
  &__wrap {
    width: 100vw;
    height: calc(100vh - calc(100vh - 100%));
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(245, 247, 250, 0.7);

    &:before {
      content: " ";
      position: absolute;
      top: -66px;
      left: 0;
      width: 100%;
      height: 66px;
      background-color: rgba(245, 247, 250, 0.7);
    }
  }
  &:not(.loading) &__loading {
    display: none;
  }

  &.loading {
    .ant-modal-header,
    .ant-modal-footer,
    .ant-modal-close {
      position: relative;
      &:after {
        content: " ";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(245, 247, 250, 0.7);
        z-index: 1;
      }
    }
    .ant-modal-body {
      min-height: 120px;
    }
    .ant-modal-close {
      position: absolute;
      pointer-events: none;
    }
    .ant-modal-header:after {
      border-radius: 8px 8px 0 0;
    }
    .ant-modal-footer:after {
      border-radius: 0 0 8px 8px;
    }
    .textarea-default:before {
      opacity: 0.5;
    }
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
    max-width: 520px;
  }

  @media only screen and (max-width: $screen-mobile-max) {
    .popup-default {
    }
    .ant-modal {
      &-header {
        padding: 24px 58px 25px 16px;
      }
      &-close {
        right: 16px;
      }
      &-body {
        padding: 16px;
      }
      &-footer {
        padding: 16px 16px 24px;
        .popup-default__footer {
          button {
            flex: 50%;
          }
        }
      }
    }
    &.mobile-full-size {
      max-width: 100vw;
      max-height: calc(100vh - calc(100vh - 100%));
      width: 100vw !important;
      height: calc(100vh - calc(100vh - 100%));
      .ant-modal {
        &-content {
          max-height: calc(100vh - calc(100vh - 100%));
          height: calc(100vh - calc(100vh - 100%));
          border-radius: 0;
        }
        &-body {
          max-height: unset;
        }
      }
      .popup-default__loading {
        position: fixed;
      }
    }
    &:not(.mobile-full-size) {
      max-width: calc(100vw - 32px);
      max-height: calc(100vh - calc(100vh - 100%) - 32px);
    }
  }
}