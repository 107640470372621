@import "src/styles/colors";
@import "src/styles/breakpoints";

.job-creation-page {
  display: block;
  padding: 204px 28px 0;

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
    padding: 276px 32px 32px;

    .bread-crumbs__wrapper {
      top: 136px;
      padding: 0 32px;
    }
  }

  @media only screen and (max-width: $screen-mobile-max) {
    padding: 272px 0 8px;

    .bread-crumbs__wrapper {
      top: 92px;
    }
  }
}