@import "src/styles/colors";

.fill-height {
  height: 100%;
}

.widget-candidates {
  margin: 0;
  width: 360px;
  padding: 24px;

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;

    .typography-h3 {
      margin-bottom: 8px;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }

  &__tabs {
    width: 100%;

    .ant-tabs-nav-wrap,
    .ant-tabs-nav-list {
      width: 100%;
    }

    .ant-tabs-nav-wrap .ant-tabs-nav-operations {
        display: none;
    }

    .ant-tabs-tab {
      width: 33.33%;
      padding: 8px 0;
      div {
        margin: 0 auto;
      }
    }
  }

  &__content {
    margin-top: 24px;

    .main-info {
      display: flex;

      &__image {
        margin: 8px 16px 0 0;
        width: 32px;
        height: 32px;
      }

      &__total {
        font-size: 40px;
        line-height: 48px;
        color: $placeholder;

        span {
          margin: 16px 0 0 8px;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
      }

      &__extra {
        margin-top: 8px;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        color: $placeholder;

        span {
          margin-left: 8px;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
        }
      }
    }

    .line-graphic {
      margin-top: 16px;
    }

    .simple-view {
      margin-top: 24px;

      > div {
        width: 33.33%;
      }

      .typography-label {
        margin-bottom: 4px;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: $placeholder;
      }

      .typography-title3 {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: $placeholder;
      }
    }

    .full-view__items {
      margin-top: 24px;

      &:empty {
        display: none;
      }

      > div {
        width: 33.33%;
      }

      .item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__title {
          margin-bottom: 4px;
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
          color: $placeholder;
        }

        &__info {
          display: flex;

          .color {
            margin-right: 8px;
            width: 20px;
            height: 20px;
            border-radius: 4px;
          }

          .label {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #717C8C;
          }
        }
      }
    }
  }
}

.widget-candidates-close-time {

  .main-info {
    &__total {
      display: flex;
    }
    &__description {
      margin: 0 8px 4px;
      align-self: flex-end;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .description-info {
    margin-top: 24px;
    width: calc(100% + 24px);
    max-height: 276px;
    padding-right: 8px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 16px !important;
      margin-bottom: 20px;
    }

    &::-webkit-scrollbar-corner {
      border-radius: 9999px !important;
    }

    &::-webkit-scrollbar-thumb {
      cursor: default !important;
      min-height: 40px;
      background-color: $border;
      border: 4px solid white !important;
      background-clip: padding-box;
      border-radius: 9999px !important;
    }
  }

  .opening-info {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;

    &__info {
      margin-right: 16px;
      overflow: hidden;
      white-space: break-spaces;
      word-break: break-word;

      .typography-label {
        color: $placeholder;
        font-weight: 400;
      }
    }

    &__time {
      white-space: nowrap;
    }
  }
}

.line-graphic {
  display: flex;

  & > div {
    height: 12px;
    border-radius: 2px;
    margin-right: 1px;

    &:last-child {
      margin-right: 0;
    }
  }
}