@import "src/styles/colors";
@import "src/styles/breakpoints";

.table-default {
  &__footer {
    padding: 6px 8px 6px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $disabled_bg;
    box-shadow: inset 0px -1px 0px $border;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    transition: opacity .3s;

    &_loading {
      padding-top: 0;
      padding-bottom: 12px;
      background-color: $white;
      & > div {
        width: 100%;
      }
    }
    &.loading {
      opacity: 0.2;
      pointer-events: none;
    }

    .typography {
      white-space: nowrap;
      color: $main;
    }

    .item-per-page {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: $main;
      display: flex;
      align-items: center;
      .select-small-default {
        margin-left: 8px;
      }
    }

    .pagination {
      display: flex;
      align-items: center;
      .input-default-wrapper {
        margin-right: 8px;
        padding-bottom: 0;
        .input-default {
          width: 56px;
          height: 28px;
        }
      }

      &__buttons {
        margin-left: 24px;
        display: flex;
        .vector {
          border-radius: $border_radius;
          cursor: pointer;
          &:hover {
            background-color: $border;
          }
          &.rotate {
            transform: rotate(-180deg);
          }
          &[disabled] {
            cursor: not-allowed;
            path {
              stroke: $disabled_text;
            }
            &:hover {
              background-color: transparent;
            }
          }
        }
      }
    }

    @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {}
    @media only screen and (max-width: $screen-mobile-max) {
      padding: 16px;
      flex-wrap: wrap;

      .pagination {
        flex: 0 0 100%;
        margin-top: 12px;

        .input-default-wrapper {
          width: 56px;
        }

        &__buttons {
          margin-left: auto;
        }
      }
    }
  }
}