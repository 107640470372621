@import "../../styles/colors";
@import "../../styles/breakpoints";

.left-panel {
  display: flex;
  flex-direction: column;
  width: 360px;
  padding: 24px;
  min-height: 550px;

  &__title {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      display: none;
      svg {
        transform: rotate(-90deg);
        path {
          stroke: $accent_70;
        }
      }

      &.open svg {
        transform: rotate(-270deg);
      }
    }
  }
  &__checkbox {
    margin-bottom: 20px;
  }
  &__candidates {
    display: none;
  }
  &__status {
    margin: 0;
  }

  &_items{
    display: block;
    height: 54px;
    padding: 8px 16px;
    margin: 0 0 8px -16px;
    width: calc(100% + 32px);

    &:last-child {
      margin-bottom: 0;
    }

    &:hover, &.active {
      background-color: $disabled_bg;
    }

    &__title {
      color: $main;
    }
    &__subtitle {
      margin-top: 2px;
      color: $lightgray;
    }
  }

  &__empty {
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 80px;
      height: 80px;
    }
    .typography {
      margin-top: 16px;
    }
  }

  .status-block {
    margin-top: 32px;

    &__title {
      margin-bottom: 4px;
      color: $blackblue;
      span {
        margin-left: 12px;
        color: $lightgray;
      }
    }

    &__button {
      margin-left: -16px;
    }
  }
  .wrapperCheckbox {
    margin-bottom: 32px;
    line-height: 18px;
  }
  .ats-select-wrapper {
    margin-bottom: 40px;
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
    margin-bottom: 12px;
    width: unset;
    min-height: unset;
    padding: 14px 24px;

    &__title {
      margin-left: -24px;
      padding: 0 24px 20px;
      width: calc(100% + 48px);
      border-bottom: 1px solid $border;

      button {
        display: block;
      }

      &:not(.open) {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
      }
    }

    &__filters {
      display: flex;
      flex-direction: column-reverse;
      position: relative;

      &:not(.open) {
        display: none;
      }
    }

    &__search,
    .status-block {
      display: none;
    }

    &__status {
      width: 320px;
    }

    &__candidates {
      margin: 16px 0 0;
      display: block;
      width: 320px;
    }

    &__checkbox {
      margin: 0;
      position: absolute;
      bottom: 4px;
      left: 372px;
    }
  }

  @media only screen and (max-width: $screen-mobile-max) {
    margin: 0 0 12px;
    width: unset;
    min-height: unset;
    padding: 14px 16px;
    border-radius: 0;

    &__title {
      margin-left: -16px;
      padding: 0 16px 20px;
      width: calc(100% + 32px);
      border-bottom: 1px solid $border;

      button {
        display: block;
      }

      &:not(.open) {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
      }
    }

    &__filters {
      display: flex;
      flex-direction: column-reverse;

      &:not(.open) {
        display: none;
      }
    }

    &__search,
    .status-block {
      display: none;
    }

    &__candidates {
      margin: 16px 0;
      display: block;
    }
    &__checkbox {
      margin: 0;
    }
  }
}
