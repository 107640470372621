@import "src/styles/colors";

.success-rate {
  .table-default__header {
    height: 64px;
  }
}

.hiring-statistics {
  margin-bottom: 28px;

  &__bold-row .ant-table-cell {
    font-weight: 700;
  }

  &__hide-row {
    .ant-table-cell {
      background-color: $whitegray;
    }
    .ant-table-cell-content__without-arrow {
      padding: 0;
    }
    &.hidden {
      display: none;
    }
  }
  .table-default-antd .ant-table-thead .ant-table-cell:first-child {
    padding-left: 38px;
  }
}

.empty-table {
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &__image {
    height: 124px;
    width: 124px;
    margin-bottom: 40px;
  }

  &__title {
    margin-bottom: 8px;
    color: $main;
  }

  &__subtitle {
    color: $placeholder;
  }

  &__loading {
    transform: translateY(-80px);
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba($disabled_bg, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}