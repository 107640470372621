@import "src/styles/colors";

.file-default {
  display: flex;
  align-items: center;

  & > div {
    flex: 1;
    overflow: hidden;
  }

  &__icon {
    flex: 0 0 32px;
    margin-right: 8px;
  }
  &__title {
    width: 100%;
    color: $main;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__subtitle {
    color: $lightgray;
  }

  &.link .file-default__title {
    color: #225FBF;
  }

  &.small {
    .file-default__icon {
      flex: 0 0 16px;
      height: 20px;
    }

    .file-default__subtitle {
      display: none;
    }
  }
}