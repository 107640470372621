@import "../../../styles/colors";

.passing-step {
  width: 100%;
  margin-top: 10px;

  &.shadow {
    padding-left: 0;
    padding-bottom: 0;

    &.loading {
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 32px;

      .select-block {
        margin-left: 0;
        margin-bottom: 30px;

        .css-p9uw5q-SkeletonTheme:first-child {
          margin-right: 20px;
        }
      }
    }
  }

  .title {
    margin-left: 24px;
  }

  .select-block {
    margin-top: 32px;
    display: flex;
    margin-left: 24px;

    .ats-select-wrapper {
      width: 270px;
      &:first-child {
        margin-right: 24px;
      }
    }
  }

  .graphic {
    margin-top: 32px;
    height: 350px;
    position: relative;
  }

  .total-legend {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    margin-left: 24px;

    .total {
      font-size: 15px;
      color: $black;

      span {
        display: inline-block;
        margin-left: 8px;
        color: #F67D37;
      }
    }

    .legends {
      display: flex;

      div {
        font-size: 15px;
        color: #474747;

        &:first-child {
          margin-right: 32px;
        }

        span {
          display: inline-block;
          margin-right: 16px;
        }

        .bar {
          width: 14px;
          height: 4px;
          background-color: $accent_70;
        }

        .line {
          width: 36px;
          height: 1px;
          background: #F67D37;
          position: relative;

          &::after {
            position: absolute;
            transform: translateY(-50%);
            left: 50%;
            margin-left: -2.5px;
            content: '';
            width: 5px;
            height: 5px;
            background-color: #F67D37;
            border-radius: 50%;
          }
        }
      }
    }
  }
}