@import "src/styles/colors";
.activity-feed {
	.ats-reminder {
		&::before {
			display: none;
		}
		&.comment-block{
			margin-top: 36px;
			margin-bottom: 0;
			padding: 36px 12px 0;
			border-radius: 0;
			border-top: 1px solid #E6E8EB;
		}
	}
	.show-more-events {
		position: relative;
		margin: 44px 0 -4px;
	}
	.blur-block {
		position: absolute;
		bottom: 20px;
		left: 0;
		width: 100%;
		height: 80px;
		background: linear-gradient(to top, white, transparent);
		pointer-events: none;
	}
	.timeline-comment {
		font-size: 13px;
		line-height: 22px;
		color: #757575;
		width: 100%;

		&_button-show-hide{
			background: none;
			cursor: pointer;
			outline: none;
			color: $accent_70;
			font-size: 15px;
			padding: 0;
			border: none;
		}
	}
	.timeline-description {
		font-size: 15px;
		line-height: 22px;
		color: #474747;
		width: 100%;
		b {
			font-weight: 400;
			color: $black;
		}
	}
	//a {
	//	color: #FB5830 !important;
    //	text-decoration: none !important;
	//}
}


.add-comment{
	margin-bottom: 25px;
	&_button{
		display: flex;
		justify-content: flex-end;
	}
	.ats-input-wrapper{
		.ats-input {
			&.textarea {
				min-height: 70px;
			}
		}
	}
}
