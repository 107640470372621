@import "src/styles/colors";
@import "src/styles/breakpoints";

.candidates-creation-page {
  display: block;
  padding: 204px 28px 0;

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
    padding: 276px 32px 0;

    .bread-crumbs {
      padding: 12px 32px;

      &__wrapper {
        top: 136px;
      }
    }
  }

  @media only screen and (max-width: $screen-mobile-max) {
    padding: 272px 0 0;

    .bread-crumbs__wrapper {
      top: 92px;
    }
  }
}