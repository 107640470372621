@import "src/styles/colors";

.textarea-default {
  padding-bottom: 20px;

  &:disabled, textarea:disabled {
    color: $main;
    border-color: $stroke;
    background-color: $disabled_bg;
    &::placeholder {
      color: $placeholder;
    }
  }

  &:after {
    margin-top: 4px;
    float: left;
    color: $placeholder;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
  &:before {
    content: '';
    background-image: url("../../dist/icons/texarea_resize.svg");
    background-size: 16px;
    background-repeat: no-repeat;
    position: absolute;
    width: 16px;
    height: 16px;
    bottom: 20px;
    right: 0;
    z-index: 1;
    pointer-events: none;
  }
  textarea {
    padding: 8px 0 8px 10px;
    min-height: 148px;
    overflow-y: scroll;
    font-family: 'Open Sans', sans-serif;
    line-height: 20px;
    box-shadow: none !important;

    &::-webkit-scrollbar {
      width: 16px !important;
      margin-bottom: 20px;
    }

    &::-webkit-scrollbar-corner {
      border-radius: 9999px !important;
    }

    &::-webkit-scrollbar-thumb {
      cursor: default !important;
      min-height: 40px;
      background-color: $border;
      border: 4px solid white !important;
      background-clip: padding-box;
      border-radius: 9999px !important;
    }

    &::-webkit-resizer {
      display: none;
    }

    &::placeholder {
      color: $lightgray;

      font-family: 'Open Sans', sans-serif;
      line-height: 20px;
    }

    &:hover {
      border-color: $disabled_text;
    }

    &:active, &:focus {
      border-color: $accent_70 !important;
    }
  }

  &-wrapper {
    position: relative;

    &.error textarea {
      border-color: $error;
    }

    .typography-label {
      margin-bottom: 8px;
      color: $main;
      display: flex;
      align-items: center;
      gap: 4px;
      .optional {
        color: $lightgray;
      }
    }
  }
}
