@import "src/styles/breakpoints";
@import "src/styles/colors";

.form-wrapper {
  width: 100%;

  .form-header {
    width: 1144px;
    margin: 0;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: $box_shadow_level_3;

    &__wrapper {
      width: 100%;
      padding: 0 28px;
      position: fixed;
      top: 108px;
      left: 0;
      z-index: 9;
      display: flex;
      justify-content: center;
      background: $whitegray;
    }

    &__buttons {
      display: flex;
      .ant-btn-primary {
        margin-left: 16px;
      }
    }
  }

  .form-body {
    width: 100%;
    display: flex;

    &__tabs {
      position: fixed;
      width: 262px;
      padding: 24px 8px;
      box-shadow: $box_shadow_level_3;

      &-block {
        &_item {
          margin-bottom: 8px;
          width: 100%;
          padding: 12px;
          cursor: pointer;
          border-radius: 6px;

          &:last-of-type {
            margin: 0;
          }

          &:hover, &.active {
            background-color: $disabled_bg;
          }

          .typography {
            color: $main;
          }
        }
      }
    }
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
    .form-header {
      width: 100%;

      &__wrapper {
        top: 176px;
        padding: 0 32px;
      }
    }
    .form-body {

      &__tabs {
        display: none;
      }

      &__content {
        width: 100%;
        flex: 0 0 100%;
      }
    }
  }

  @media only screen and (max-width: $screen-mobile-max) {
    .form-header {
      padding: 24px 16px;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 0;

      &__wrapper {
        top: 132px;
        padding: 0;
      }

      &__title {
        margin-bottom: 16px;
      }

      &__buttons {
        width: 100%;

        button {
          width: 100%;

          .ant-btn-primary {
            margin-left: 12px;
          }
        }
      }
    }

    .form-body {

      &__tabs {
        display: none;
      }

      &__content {
        width: 100%;
        flex: 0 0 100%;
        border-radius: 0;
        box-shadow: none;
      }
    }
  }
}