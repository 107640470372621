@import "src/styles/breakpoints";

.candidate-details-page {
  display: block;

  .bread-crumbs {
    padding-top: 0;

    &__wrapper {
      position: static;
      padding: 0;
    }
  }

  &__main-info {
    display: flex;
  }

  .candidate-job-experience {
    width: 752px;
    padding: 24px 0;

    &.is-self-submitted {
      padding: 0;
    }

    &__tabs {
      width: 100%;
      .ant-tabs-nav {
        padding: 0 24px;
      }
    }

    &:has(.job-assigned) {
      padding-bottom: 0;
    }
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
    padding: 148px 32px 0;

    &__main-info {
      margin-bottom: 16px;
      flex-direction: column;
    }

    .candidate-job-experience {
      width: 100%;
    }
  }

  @media only screen and (max-width: $screen-mobile-max) {
    padding-top: 104px;

    &__main-info {
      flex-direction: column;
    }

    .candidate-job-experience {
      width: 100%;
      padding: 24px 0 32px;
      border-radius: 0;
      box-shadow: none;

      &__tabs .ant-tabs-nav {
        padding: 0 16px;
      }
    }
  }
}

.view-candidate_right-part {
  margin-top: 12px;

  @media only screen and (max-width: $screen-mobile-max) {
    display: none;
  }
}