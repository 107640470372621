@import "src/styles/colors";
@import "src/styles/breakpoints";

.jobs-list {
  &-subtitle {
    display: flex;
    gap: 20px;
    width: max-content;

    .typography-h3 {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 12px;
      line-height: 14px;
      font-weight: 600;
      color: #181B21;
      span {
        color: #97A3B5;
      }
    }
  }
  .table-jobs {
    width: 752px;
    height: max-content;

    .expanded-icon-mobile {
      display: none;
    }
    .ant-table-tbody .table-jobs,
    .table-mobile-default__content .table-jobs {

      &__name {
        padding-left: 38px;

        a {
          display: block;
          color: $main;

          &:hover {
            color: $accent_70;
            text-decoration: underline;
          }
        }
      }

      &__name a,
      &__team-lead {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &__status .status-default {
        position: absolute;
        top: 7px;
      }

      &__candidates-count {
        text-align: right;
      }


      &__opening {
        padding: 8px 12px 8px 38px;
        display: flex;
        align-items: center;
        background-color: $whitegray;
        cursor: pointer;
        text-decoration: none;

        &:hover {
          background-color: $disabled_bg;
        }

        .priority {
          margin-right: 8px;
        }
        .level {
          width: 52px;
          margin-right: 32px;
        }
        .expected-date {
          width: 160px;
          margin-right: 32px;
        }
        .status {
          width: 114px;
          margin-right: 32px;
        }
        .resolution {
          width: 248px;
        }

        .typography {
          color: $placeholder;
          vertical-align: middle;
        }

        &__link {
          padding: 10px 38px;
          background-color: $whitegray;
        }

        &_empty {
          padding: 16px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $main;
          background-color: $whitegray;
          box-shadow: inset 0 -1px 0 $border;

          .loading-overflow {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid $border;

            svg {
              width: 24px;
              height: 24px;
            }
          }
        }

        &:last-child {
          box-shadow: inset 0 -1px 0 $border;
        }
      }
    }

    .ant-table-row-expand-icon-cell {
      position: relative;

      .expanded-icon {
        height: 16px;
        width: 16px;
        position: absolute;
        top: 10px;
        left: 10px;
        cursor: pointer;
        transform: rotate(180deg);
        border-radius: 4px;

        &:hover {
          background-color: $border;
        }

        &.expanded {
          transform: rotate(-90deg);
        }
      }
    }

    .empty-template {
      height: 640px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 16px;

      img {
        width: 124px;
        height: 124px;
        margin-bottom: 40px;
      }

      .typography-h2 {
        color: $main;
      }

      .typography-h3 {
        margin-top: 8px;
        color: $placeholder;
      }
    }
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
    flex-direction: column;

    .table-jobs {
      width: 100%;

      .table-default__header__cell {
        height: 48px !important;
      }

      .table-job__cell:first-child,
      .table-default__header__cell:first-child {
        flex: 1 !important;
        width: unset !important;
      }

      .table-job__cell:nth-child(2),
      .table-default__header__cell:nth-child(2) {
        width: 136px !important;
      }

      .empty-template {
        height: 368px;
      }
    }
  }

  @media only screen and (max-width: $screen-mobile-max) {
    flex-direction: column;
    .table-jobs {
      width: 100%;

      colgroup col {
        width: 0 !important;
      }

      .table-default__header {
        justify-content: space-between;

        .table-extra {
          width: auto;
          justify-content: flex-end;
        }
      }

      .table-default-antd__content {
        display: block;

        .expanded-icon {
          display: none;
        }

        .expanded-icon-mobile {
          display: block;
        }

        .ant-table-cell {
          background-color: $white;
        }

        .table-mobile-default__content {
          width: 100vw !important;
          background-color: $white;

          .item {
            border: none;

            .item__row .title {
              text-align: left;
            }

            .table-jobs__name {
              padding: 0;
            }

            .table-jobs__status .status-default {
              position: static;
            }
          }
        }

        .expanded-icon-mobile {
          margin: 0 0 24px 8px;
        }

        .table-jobs__opening {
          width: 100%;
          padding: 16px;
          position: relative;
          flex-direction: column;
          align-items: start;

          .priority {
            position: absolute;
            top: 16px;
            left: 16px;
          }
          .level {
            width: auto;
            padding: 4px  0 4px 32px;
            margin: 0 0 12px;
          }

          .status {
            margin: 0;
            width: auto;
            position: absolute;
            top: 16px;
            right: 16px;
          }

          &__link {
            padding: 8px;
          }
        }
      }

      .empty-template {
        height: 364px;
      }

      .ant-table-thead {
        display: none;
      }
    }
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {}

  @media only screen and (max-width: $screen-mobile-max) {
    &-subtitle {
      flex-direction: column;
      gap: 10px;
    }
  }
}