@import "src/styles/colors";
@import "src/styles/breakpoints";

.opening-default {
  padding: 24px 24px 24px 56px;

  &__header {
    margin-bottom: 24px;
    position: relative;
    display: flex;
    justify-content: space-between;

    &__main-info {
      display: flex;
      align-items: center;
      position: relative;
    }

    &__extra-info {
      margin-top: 4px;
      color: $lightgray;
    }
  }

  &__status {
    margin-left: 12px;
  }

  .priority {
    position: absolute;
    left: -32px;
  }

  &__team-lead {
    margin-right: 32px;
    min-width: 206px;
    flex: 206px 0 0;
  }

  &__body {
    display: flex;

    &__info {
      width: 100%;
      display: flex;
    }

    &__main-info {
      margin-right: 32px;
      width: 262px;
      flex: 262px 0 0;

      .info-block {
        margin-bottom: 16px;

        .typography-label {
          margin-bottom: 4px;
          font-weight: 400;
          color: $lightgray;
        }
        .typography-text {
          color: $main;
        }
      }
    }

    &__extra-info {
      margin-top: 8px;
      margin-left: 238px;

      &.hidden {
        display: none;
      }

      & > .typography-text {
        margin: 8px 0 4px;
      }

      &__label {
        font-weight: 400;
        color: $lightgray;
      }

      &__footer {
        display: flex;

        .info-block {
          margin-bottom: 16px;

          .typography-label {
            margin-bottom: 4px;
            font-weight: 400;
            color: $lightgray;
          }

          a {
            display: block;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &__persons {
          margin-right: 24px;
          width: 262px;
        }
      }
    }
  }

  &__comment {
    flex: 1;
    overflow: hidden;

    .typography-label {
      margin-bottom: 4px;
    }
  }

  &__buttons {
    display: flex;
    > button {
      margin-right: 16px;
    }
  }

  &__show-more {
    margin-top: 24px;
    padding: 0 0 0 32px;
    height: auto;
    transform: translateX(-4px);
  }

  &__label {
    margin: 0 0 24px -32px;
    padding: 16px 32px;
    background-color: $disabled_bg;
    border-radius: $border_radius;
    font-size: 14px;
    line-height: 20px;
    color: $lightgray;

    span {
      color: $main;
    }
  }

  &__extra-user {
    width: 262px;
  }

  &.resolution {
    .opening-default {
      &__body {
        flex-direction: column;
      }
      &__team-lead {
        margin-bottom: 24px;
        width: 100%;
        max-width: 262px;
        flex: unset;
      }
    }
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {

    &__body {
      padding-top: 60px;
      position: relative;

      &__extra-info {
        margin-left: 0;
      }
    }

    &__team-lead {
      margin: 0;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  @media only screen and (max-width: $screen-mobile-max) {
    padding: 16px;

    &__header {
      padding-left: 32px;
      flex-direction: column-reverse;

      &__main-info {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__status {
      margin: 5px 0 0 0;
    }

    &__buttons {
      margin: 0 0 16px -32px;
      padding-top: 36px;
      justify-content: flex-end;
      & > button {
        margin: 0;
        flex: 1;
        position: relative;

        &:first-child {
          margin-right: 12px;
        }
      }

      .button-menu-default {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    &__body {
      padding-top: 60px;
      position: relative;
      flex-direction: column;

      &__info {
        flex-direction: column;
      }

      &__main-info {
        width: unset;
        flex: unset;
      }

      &__extra-info {
        margin-left: 0;
      }
    }

    &__team-lead {
      margin: 0;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}