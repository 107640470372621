@import "src/styles/colors";
@import "src/styles/breakpoints";

.job-experience {
  padding: 24px;
  &__label {
    margin-bottom: 8px;
  }

  &__block {
    margin-bottom: 32px;

    & > div {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.level {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__sub-block {
    margin-bottom: 16px;
  }

  &__specialities {
    margin-bottom: 32px;

    .ant-col:first-child {
      margin-bottom: 4px;
    }

    .button-default {
      margin-top: 8px;
    }
  }

  .file-default {
    margin-top: 8px;
  }

  .cv-link {
    display: flex;
    align-items: center;

    &__icon {
      flex: 0 0 16px;

      path {
        fill: $placeholder;
      }
    }

    &__text {
      margin-left: 12px;
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__loading {
    padding: 24px;
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
  }

  @media only screen and (max-width: $screen-mobile-max) {
    padding: 24px 16px 0;
  }
}