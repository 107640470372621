@import "src/styles/colors";

.popup-edit-hired {

  .candidate-info {
    margin-bottom: 24px;
    .typography-label {
      color: $lightgray;

      &:not(:empty) {
        margin-top: 4px;
      }
    }
  }

  .months {
    margin-top: 32px;
  }
}