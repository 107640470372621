.popup-new-reminder {
  .datepicker-default {
    width: 100%;
  }
  .reminder-calendar-checker {
    margin: 16px 0 0;
    display: flex;
    align-items: center;
    > svg {
      margin-left: 12px;
    }
  }
}