@import "src/styles/colors";
@import "src/styles/breakpoints";
@import "src/styles/mixin";

.dashboard-hr-lead {
  width: 100%;
  max-width: 1144px;
  padding: 84px 0 32px;
  margin: 0 auto;

  &__tabs {
    margin-bottom: 4px;
  }

  &__banner-wrapper {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;

    .top-banner {
      margin-bottom: 0;
      width: 556px;
      height: 264px;
      border: 1px solid #E9EFFB;
      border-radius: 16px;
      background-image: url('../../dist/images/dashboard_banner556.svg');
      background-color: #E9EFFB;
      background-size: cover;
      background-position: center center;
      overflow: hidden;
    }
  }

  .all-openings {

    thead {
      transition: none !important;
    }

    .self-submitted-candidates .ant-table-thead .ant-table-cell:nth-child(2) {
      padding-left: 44px !important;
    }

    &__empty {
      padding: 44px;
      svg {
        width: 124px;
        height: 124px;
      }

      .typography-h3 {
        margin-top: 40px;
        color: $main;
      }

      .typography-text {
        margin-top: 8px;
        color: $main;
      }
    }

    &__sub-table {
      margin-bottom: 0;

      .ant-table-cell {
        height: 72px;
      }

      .all-openings {
        &__job {
          a {
            color: $main;
            position: relative;

            &:hover {
              color: $accent_70;
            }

            .typography-text {
              @include multipleRowsEllipsis(2);
              padding-left: 32px;
            }

            .priority {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
        &__expected-date {
          .error {
            color: $error;
          }
        }
        &__level > div,
        &__team-lead > div {
          @include multipleRowsEllipsis(2);
        }
        &__status {
          .status-default {
            width: fit-content;
            white-space: break-spaces;
          }
        }
        &__action {
          &, .ant-table-cell-content {
            display: flex;
            align-items: center;

            .ant-btn-primary {
              margin-left: 12px;
            }
          }
        }
      }

      &.job {
        .all-openings__level {
          padding-left: 44px;
          position: relative;

          svg {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 12px;
          }
        }
      }
    }

    .table-extra {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 8px;

      .select-default {
        margin: 0 24px 0 0;
        width: 320px;
        .select-default__tag {
          max-width: 260px; // will be removed next
        }
      }

      .divider-default {
        height: 32px;
      }

      .filter-selectors {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .filter-container {
          display: flex;
          align-items: center;

          .typography-caption {
            margin-right: 8px;
            color: $placeholder;
            white-space: nowrap;
          }
        }
      }

      .select-default {
        margin: 0 24px 0 0;
        width: 320px;
        .select-default__tag {
          max-width: 260px; // will be removed next
        }
      }
    }

    .ant-table-row-level-0 {
      height: 48px;
    }

    .expanded-row {
      width: 1144px;
      height: 48px;
      padding: 0 12px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $whitegray;
      border-bottom: 1px solid $border;

      &__info {
        padding-left: 32px;
        position: relative;
        text-align: left;

        .name {
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          cursor: pointer;

          span {
            margin-left: 8px;
            color: $lightgray;
          }

          &__extra {
            font-size: 12px;
            line-height: 16px;
            color: $placeholder;
          }
        }
      }

      &__extra {
        display: flex;
        align-items: center;

        .typography-caption {
          margin-left: 40px;
          color: $placeholder;
        }
      }

      &__icon {
        position: absolute;
        top: 50%;
        left: 12px;
        transform: rotate(180deg) translate(50%, 50%);
        cursor: pointer;
        &.expanded {
          transform: rotate(270deg) translate(50%, -50%);
        }
      }
    }
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
    padding: 168px 32px 16px;

    &__banner-wrapper {
      .top-banner {
        width: 224px;
        flex: 224px 0 0;
      }

      .new-jobs {
        margin-left: 32px;
        flex: 1;
      }
    }

    .table-extra, .active-candidates__header-extra {
      width: 100%;
    }

    .table-default__header {
      flex-direction: column;
      align-items: flex-start;

      .table-info {
        margin-bottom: 12px;
      }

      .recruiters-filter {
        margin-right: auto;
        max-width: 298px;
      }
    }

    .all-openings__empty {
      width: calc(100vw - 64px);
    }
  }

  @media only screen and (max-width: $screen-mobile-max) {
    padding: 108px 0 8px;

    &__banner-wrapper {
      .top-banner {
        display: none;
      }

      .new-jobs {
        width: 100%;
        border-radius: 0;
      }
    }

    .all-openings {

      &__empty {
        width: 100vw;
        padding: 48px 16px;

        .typography-h3 {
          margin-top: 24px;
        }
      }

      .table-default__header {
        flex-direction: column;
        align-items: flex-start;

        .table-info {
          margin-bottom: 4px;
        }
      }

      .table-extra {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;

        .select-default {
          margin: 0;
          width: 100%;
        }

        .divider-default {
          display: none;
        }

        .filter-selectors {
          flex-direction: column;
          align-items: flex-start;

          .filter-container {
            margin-top: 8px;
          }

          .select-small-default ul {
            right: unset;
            left: 0;
          }
        }
      }

      .ant-table-thead {
        display: none;
      }
      .ant-table-content {
        overflow: hidden;

        table {
          table-layout: unset !important;
        }

        .ant-table-cell {
          height: 100px;
        }

        .expanded-row {
          width: 100vw;
          height: 100px;
          padding: 16px 16px 12px;
          flex-direction: column;
          align-items: flex-start;

          &__extra {
            margin-top:4px;
            width: 100%;
            flex-direction: column;
            align-items: flex-start;

            .typography-caption {
              margin-left: 32px;
              width: auto;
              text-align: left;

              &:nth-child(2) {
                margin-top: 8px;
              }
            }
          }
        }
      }

      &.job {
        .ant-table-content {
          .ant-table-cell {
            height: 84px;
          }

          .expanded-row {
            height: 84px;
          }
        }

        .all-openings__level {
          padding-left: 32px;

          svg {
            left: 0;
          }
        }
      }

      &__action {
        &, a, button {
          width: 100%;
        }
        .ant-btn-primary {
          margin-top: 8px;
        }
      }
    }
    .ant-table-expanded-row-level-1 {
      .all-openings__sub-table {
        display: none;

        &-mobile {
          display: block;
        }
      }
    }

    .active-candidates {
      .recruiters-filter {
        margin-bottom: 8px !important;
        max-width: 100%;
      }

      .table-extra {
        flex-direction: column;
        align-items: flex-start;

        .table-info {
          margin-bottom: 4px;
        }
        .divider-default {
          display: none;
        }
      }

      &:not(.actions):not(.jobs):not(.steps) {
        .table-default-antd__content {
          display: none;
        }

        .table-mobile-default__content {
          display: block;
        }
      }

      .table-default__header {
        flex-direction: column;
        align-items: flex-start;

        .table-info {
          margin-bottom: 4px;
        }
      }
      .active-candidates__header-extra {
        flex-direction: column;
        align-items: flex-start;

        .divider-default {
          display: none;
        }

        .filter-container {
          margin-top: 8px;
        }
      }

      .ant-table-thead {
        display: none;
      }
      .ant-table-content {
        overflow: hidden;

        table {
          table-layout: unset !important;
        }

        .ant-table-cell {
          height: 100px;
        }

        .expanded-row {
          width: 100vw;
          height: 100px;
          padding: 16px 16px 12px;
          flex-direction: column;
          align-items: flex-start;

          &__extra {
            margin-top:4px;
            width: 100%;
            justify-content: space-between;

            .typography-caption {
              margin-left: 32px;
              width: auto;
              text-align: left;
            }
          }
        }

        .table-default-antd__content {
          display: none;
        }
        .active-candidates__sub-table-mobile {
          display: block;
        }
      }
    }
  }
}

.active-candidates {

  thead {
    transition: none !important;
  }

  .recruiters-filter {
    width: 100% !important;
    max-width: 280px;
  }

  &__empty {
    padding: 44px;
    svg {
      width: 124px;
      height: 124px;
    }

    .typography-h3 {
      margin-top: 40px;
      color: $main;
    }

    .typography-text {
      margin-top: 8px;
      color: $main;
    }
  }

  &__name {
    .typography-text {
      color: $main;
    }
    .typography-caption {
      margin-top: 2px;
      color: $placeholder;
    }
    a {
      transition: .3s;
    }
    a:hover {
      .typography-text,
      .typography-caption {
        color: $accent_70;
      }
    }
  }
  &__job {
    .typography-caption {
      margin-top: 2px;
      color: $placeholder;

      &:empty {
        display: none;
      }
    }
  }
  &__cvs {
    .file-default {
      margin-bottom: 2px;
    }
    button {
      margin-top: 8px;
    }
  }

  &__action,
  &__status-step {
    .typography-caption {
      margin-top: 4px;
      color: $placeholder;
    }
  }

  &__action {
    .time, .link {
      margin-top: 4px;

      &:empty {
        display: none;
      }
    }

    .link {
      color: $accent_70;
      &:hover {
        text-decoration: underline;
      }
    }
    .action-button {
      display: inline-block;

      &.disabled {
        pointer-events: none;
      }
    }

    .draft-status {
      display: inline-block;
      margin-top: 4px;
      font-size: 14px;
      line-height: 20px;
      color: #717C8C;
    }
  }

  .table-job__cell {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .table-extra {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;

    .select-default {
      margin: 0 24px 0 0;
      width: 320px;
      .select-default__tag {
        max-width: 260px; // will be removed next
      }
    }

    .checkbox-default {
      margin-right: 16px;
    }

    .filter-container {
      display: flex;
      align-items: center;

      .typography-caption {
        margin-right: 8px;
        color: $placeholder;
      }
    }
  }

  .ant-table-row-level-0 {
    height: 48px;
  }

  .expanded-row {
    width: 1144px;
    height: 48px;
    padding: 0 12px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $whitegray;
    border-bottom: 1px solid $border;

    &__info {
      padding-left: 32px;
      position: relative;
      text-align: left;

      .name {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        cursor: pointer;

        span {
          margin-left: 8px;
          color: $lightgray;
        }
      }
      .extra {
        font-size: 12px;
        line-height: 16px;
        color: $placeholder;
      }
    }

    &__icon {
      position: absolute;
      top: 50%;
      left: 12px;
      transform: rotate(180deg) translate(50%, 50%);
      cursor: pointer;
      &.expanded {
        transform: rotate(270deg) translate(50%, -50%);
      }
    }
  }

  &__sub-table {
    margin-bottom: 0;
  }
}