@import "src/styles/colors";
@import "src/styles/mixin";

.rich-area-default {
  margin-bottom: 16px;
  width: 100%;
  position: relative;

  &:after {
    content: '';
    background-image: url("../../dist/icons/texarea_resize.svg");
    background-size: 16px;
    background-repeat: no-repeat;
    position: absolute;
    width: 16px;
    height: 16px;
    bottom: 20px;
    right: 0;
    z-index: 1;
    pointer-events: none;
  }

  .typography-label {
    margin-bottom: 8px;
    display: flex;
    gap: 8px;

    .optional {
      color: $lightgray;
    }

    &:empty {
      display: none;
    }
  }

  &__rich-area {
    min-height: 100px;
    height: 148px;
    overflow-y: scroll;
    resize: vertical;

    display: flex;
    flex-direction: column;

    padding: 0 0 10px 8px;
    background-color: $white;

    border: 1px solid $stroke;
    border-radius: $border_radius;
    transition: border-color 0.3s;

    &::-webkit-resizer {
      display: none;
    }

    &:hover {
      border-color: $disabled_text;
    }
    &:focus-within {
      border-color: $accent_70;
    }

    &::-webkit-scrollbar {
      width: 16px !important;
      margin-bottom: 20px;
    }

    &::-webkit-scrollbar-corner {
      border-radius: 9999px !important;
    }

    &::-webkit-scrollbar-thumb {
      cursor: default !important;
      min-height: 40px;
      background-color: $border;
      border: 4px solid white !important;
      background-clip: padding-box;
      border-radius: 9999px !important;
    }

    .ProseMirror {
      height: 100%;
      outline: none;

      > * {
        margin: 0 0 12px 0;
        &:last-child {
          margin: 0;
        }
      }
    }

    .placeholder {
      height: 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $lightgray;
      pointer-events: none;
    }
  }

  &.disabled {
    .rich-area-default__rich-area {
      cursor: not-allowed;
      border-color: $stroke;
      background-color: $disabled_bg;
      .rich-area-default__rich-content {
        pointer-events: none;
      }
    }
    .rich-area-default__menu {
      pointer-events: none;
      background-color: $disabled_bg;
    }
  }
  &__rich-content {
    flex: 1;

    .rich-text {
      word-wrap: break-word;
      white-space: break-spaces;
      -webkit-font-variant-ligatures: none;
      font-variant-ligatures: none;
      font-feature-settings: "liga" 0;
      max-height: 110px;
      overflow: hidden;

      &.open {
        max-height: initial;
      }

      & > *:last-child {
        margin-bottom: 0;
      }
    }

    &.rich-text__wrapper button {
      margin-top: 8px;
      padding: 0;
      height: auto;
      background-color: $white !important;
      border-color: transparent !important;
    }

    ul, ol {
      padding-inline-start: 16px;
      li {
        p {
          display: inline;
          margin-bottom: 4px;
          font-family: 'Open Sans', sans-serif;
          font-size: 14px;
          line-height: 20px;

          color: $main;
        }
      }
    }
    ul {
      list-style-type: none;
      li {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          left: -15px;
          top: 11px;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: $accent_70
        }
      }
    }
   p.is-empty {
      position: relative;
      &:after {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        content: attr(data-placeholder);
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        line-height: 20px;
        color: $lightgray;
        pointer-events: none;
      }
     &.is-editor-empty:after {
       opacity: 1;
     }
    }
  }

  &__menu {
    display: flex;
    flex-wrap: wrap;
    padding: 8px 0;
    background-color: $white;
    position: sticky;
    top: 0;
    z-index: 1;

    .divider {
      margin: 4px 8px 4px 0;

      height: 16px;
      width: 1px;
      background-color: $border;
      border-radius: 1px;
    }

    .rich-area__icon {
      margin-right: 8px;
      border-radius: $border_radius;
      cursor: pointer;
      &.active {
        path {
          fill: $accent_80;
        }
      }
      &:hover {
        background-color: $accent_30;
        path {
          fill: $accent_70;
        }
      }
      &:active {
        background-color: $accent_30;
        path {
          fill: $accent_80;
        }
      }
    }
  }
  &__counter {
    margin-top: 4px;
    color: $placeholder;
  }
}

.plain-rich-text {
  white-space: nowrap;

  .rich-text {
    overflow: initial;
  }

  @include multipleRowsEllipsis(1);

  .button-default {
    display: none;
  }
}

.ant-form-item-has-error {
  padding-bottom: 16px;
  .rich-area-default {
    margin-bottom: 0;
    &__rich-area {
      border-color: $error;
    }
  }
}