@import 'src/styles/colors.scss';
@import "src/styles/mixin";
@import "src/styles/breakpoints";

.self-submitted-candidates {
  .typography-text {
    color: $main;
  }
  .typography-caption {
    color: $placeholder;
  }

  .menu-list {
    width: 188px;
  }

  .table-default__header {
    flex-direction: column;
    align-items: flex-start;
    .table-info {
      margin-bottom: 4px;
    }

    .table-extra {
      width: 100%;
      justify-content: space-between;
    }

    button {
      margin: 0 0 0 auto;
    }
  }

  &__header-extra {
    width: 100%;
    display: flex;
    align-items: center;

    .input-default-wrapper {
      margin-right: 24px;
      padding: 0;
      width: 260px;
    }

    .datepicker-default-wrapper {
      margin: 0;
    }
  }

  &__vacancy-info {
    a {
      margin-top: 4px;
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &__viewed-by .typography {
    @include multipleRowsEllipsis(2);
  }

  &__action .button-menu-default {
    margin: 0 0 0 auto;
    display: block;
    width: 36px;
  }

  &__empty {
    padding: 44px;
    svg {
      display: block;
      margin: 0 auto;
      width: 124px;
      height: 124px;
    }

    .typography-h3 {
      text-align: center;
      margin-top: 40px;
      color: $main;
    }

    .typography-text {
      margin-top: 8px;
      color: $placeholder;
    }
  }

  &_mobile-footer {
    display: none;
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
  }

  @media only screen and (max-width: 960px) {
    .self-submitted-candidates__header-extra {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      width: 100%;

      .input-default-wrapper,
      .datepicker-default-wrapper {
        width: 100%;
      }
    }
  }
  @media only screen and (max-width: $screen-mobile-max) {
    box-shadow: none;

    .table-default__header button {
      display: none;
    }

    .table-default-antd__content,
    .table-default__footer {
      display: none;
    }

    &_mobile {
      display: block;
      background-color: #fff;

      .item__row {
        overflow: hidden;

        .self-submitted-candidates__vacancy-info a {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      &-footer {
        display: block;
      }

      .menu-list {
        width: 240px;
        left: 16px !important;
        transform: none !important;
      }
    }
  }
}