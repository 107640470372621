@import "src/styles/colors";
@import "src/styles/breakpoints";

.experience-list {
  &__item {
    margin-bottom: 12px;
    padding: 72px 24px 24px;
    box-shadow: $box_shadow_level_3;
    position: relative;

    &__delete {
      position: absolute;
      top: 16px;
      right: 16px;

      path {fill: $accent_70;}
      &:focus path {fill: $accent_60}
      &:active path {fill: $accent_80}
    }

    .datepicker-default {
      width: 100%;
    }

    .checkbox-default {
      margin-bottom: 16px;
    }

    &.no-delete {
      padding-top: 24px;
      .experience-list__item__delete {
        display: none;
      }
    }
  }
  &__add {
    margin-top: 24px;
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
  }

  @media only screen and (max-width: $screen-mobile-max) {
    &__item {
      padding: 52px 0 16px;
      border-radius: 0;
      box-shadow: none;
    }

    &.no-delete {
      padding-top: 24px;
    }
  }

  &__add {
    margin-top: 8px;
  }
}