@import "src/styles/colors";
@import "src/styles/breakpoints";
@import "src/styles/mixin";

.candidate-list-page {
  .table-candidates {
    width: 752px;
    height: max-content;

    .ant-table-cell {
      vertical-align: top;

      &_speciality {
        @include multipleRowsEllipsis(2);
      }
    }

    &__extra {
      display: flex;
      align-items: center;

      .checkbox-default {
        margin-right: 16px;
      }
    }

    &__empty {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 500px;

      svg {
        width: 124px;
        height: 124px;
      }

      .typography-h3 {
        margin-top: 40px;
        color: $main;
      }

      .typography-text {
        margin-top: 8px;
        color: $main;
      }
    }

    .sorting-list {
      width: 208px;
    }

    .ant-table-tbody {
      a {
        &:hover .main-name, &:hover .sub-name {
          color: $accent_70;
        }
        &:active .main-name, &:active .sub-name  {
          color: $accent_90;
        }
      }

      .main-name, .sub-name {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .main-name {
        margin-bottom: 2px;
        color: $main;
        font-size: 14px;
        line-height: 16px;

        &_empty {
          margin-bottom: 20px;
        }
      }
      .sub-name {
        margin-bottom: 20px;
        font-size: 12px;
        line-height: 16px;
        color: $placeholder;
      }

      button {
        margin-top: 20px;
      }
      .sub-status {
        margin-bottom: 32px;

        &:last-of-type {
          margin-bottom: 16px;
        }

        &_empty {
          height: 16px;
          margin-bottom: 20px;

          &:last-of-type {
            margin-bottom: 16px;
          }
        }
      }
    }

    &_mobile {
      display: none;
    }
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
    flex-direction: column;

    .table-candidates {
      width: 100%;
    }
  }

  @media only screen and (max-width: $screen-mobile-max) {
    padding-top: 108px;
    flex-direction: column;

    .table-candidates {
      display: none;

      &__empty {
        height: 364px;
        box-shadow: 0 2px 4px 0 rgba(80, 89, 102, 0.2);


        + .table-default__footer {
          display: none;
        }
      }

      &_mobile {
        display: block;

        .candidate-item {
          padding: 16px;
          border-bottom: 1px solid $border;

          &__row {
            display: flex;

            &:not(:last-child) {
              margin-bottom: 8px;
            }
            .title {
              width: 120px;
              flex: 0 0 120px;
              padding-right: 8px;
              svg {
                display: none;
              }
            }

            .name {
              flex: 1;
            }

            .content .title {
              position: absolute;
              left: 16px;
            }
          }

          .main-name,
          .sub-name {
            display: block;
          }

          .sub-name {
            margin-bottom: 8px;
          }

          .sub-name:not(:last-of-type),
          .sub-status:not(:last-of-type) {
            margin-bottom: 12px;
          }

          .button-default {
            margin-top: 8px;
          }
        }

        .table-default__header {
          flex-direction: column;
          align-items: flex-start;

          .table-candidates__extra {
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
          }
        }
      }
    }
  }
}
