@import "src/styles/colors";
@import "src/styles/breakpoints";

.active-openings {
  margin-bottom: 0;
  width: 360px;
  height: 264px;

  &__header {
    padding: 24px;
    border-bottom: 1px solid $border;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    .number {
      margin-left: 8px;
      color: #97A3B5;
    }
  }

  &__content {
    height: calc(100% - 73px);
    padding: 16px 4px 0 24px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 20px !important;
      border: 6px solid white !important;
      background-color: $disabled_bg;
      border-radius: 9999px !important;
    }

    &::-webkit-scrollbar-corner {
      border-radius: 9999px !important;
    }

    &::-webkit-scrollbar-thumb {
      cursor: default !important;
      min-height: 40px;
      background-color: $border;
      border: 6px solid transparent !important;
      background-clip: padding-box;
      border-radius: 9999px !important;
    }

    &-loading {
      padding: 24px;
    }

    &-empty {
      padding: 38px 24px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .empty-image {
        width: 80px;
        height: 80px;
        margin-bottom: 16px;
      }
    }

    .active-opening {
      margin-bottom: 16px;
      padding: 6px 0 0 44px;
      position: relative;

      .priority {
        position: absolute;
        top: 0;
        left: 0;
        width: 32px;
        height: 32px;
      }

      &__service {
        color: $lightgray;
      }

      a {
        display: block;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #393F4A;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:hover {
          text-decoration: underline;
          color: $accent_70;
        }
      }

      &__status {
        margin-top: 10px;
      }
    }
  }

  .show-more {
    display: none;
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
  }

  @media only screen and (max-width: $screen-mobile-max) {
    height: auto;

    &__header {
      padding: 24px 16px;
      font-size: 16px;
      line-height: 20px;
    }

    &__content {
      padding: 16px 16px 28px;
      overflow: visible;

      &:not(.show-all) {
        .active-opening:nth-child(n+4) {
          display: none;
        }
      }
    }

    .active-opening {
      flex-direction: column;
      position: relative;

      .priority {
        position: absolute;
        top: 0;
        left: 0;
      }

      &__action {
        margin: 16px 0 0;
        flex: none;
      }
    }

    .show-more {
      margin-top: 20px;
      display: flex;
    }

    &:not(.allShown) .new-jobs__content .new-job:nth-child(n+4) {
      display: none;
    }
  }
}