@import "src/styles/colors";

.divider-default {
  &.horizontal {
    margin: 24px 0;
    width: 100%;
    border-top: 1px solid $border,
  }
  &.vertical {
    margin: 0 8px;
    height: 100%;
    width: 1px;
    border-left: 1px solid $border,
  }
}