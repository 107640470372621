@import "../../styles/colors";

.tooltipDesign {
  padding: 13px;
  position: absolute;
  transform: translate(-50%, 0);
  transition: all 0.3s ease;
  background-color: $white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 6px;

  &:hover {
    display: block !important;
  }

  .tooltipUl {
    li {
      display: flex;

      .left,
      .right {
        display: flex;
        flex-direction: column;

        span {
          display: inline-block;
          font-size: 14px;
          white-space: nowrap;
          &:not(:first-child) {
            margin-top: 6px;
          }
        }
      }

      .left {
        span {
          color: #757575;
        }
      }

      .right {
        margin-left: 8px;
        span {
          color: $black;
        }
      }
    }
  }
}