@import "src/styles/colors";

.popup-submit-offer-decision {
  .ant-select-selection-item {
    .typography-text {
      line-height: 28px;
    }
    .typography-caption {
      display: none;
    }
  }
  .opening-select-option {
    .typography-text span,
    .typography-caption {
      color: $placeholder;
    }
  }
}