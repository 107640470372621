@import 'src/styles/colors.scss';

.button-group-default {
  margin-bottom: 16px;

  &__group-wrapper {
    display: flex;

    .input-default-wrapper {
      margin-left: 20px;
      padding: 0;
      width: 160px;
    }
  }

  .typography-label {
    margin-bottom: 8px;
  }

  .ant-radio-button-wrapper {
    padding: 8px 16px;
    border-color: $stroke;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: $lightgray;

    &:focus-within {
      box-shadow: none;
    }

    &-checked {
      background-color: $accent_70;
      border-color: $accent_70 !important;
      color: $white;
      &:hover, &:before {
        background-color: $accent_70 !important;
        color: $white;
      }
    }
  }
}

.ant-form-item-has-error .ant-radio-button-wrapper {
  border-color: $error;
  &:before {
    background-color: $error;
  }
}
