@import "src/styles/colors";

.user-default {
  height: min-content;
  width: max-content;
  display: flex;

  &__avatar {
    flex: 32px 0 0;
    margin-right: 12px;

    .empty {
      width: 100%;
      height: 100%;
    }
  }

  & > span:not(&__avatar) {
    flex: 1;
    overflow: hidden;
  }

  &__name, &__roles {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:empty {
      display: none;
    }
    &.no-roles {
      margin-top: 6px;
    }
  }

  &__roles {
    color: $lightgray;
  }

  .optional-tag {
    margin-left: 8px;
    color: $lightgray;
  }
}