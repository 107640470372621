@import "src/styles/colors";

.analytics-candidates {
  .ant-table-cell-fix-left:nth-child(2) {
    left: 246px !important;
  }

  .error {
    color: $error;
  }
  .success {
    color: $success;
  }
}
