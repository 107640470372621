$screen-desktop: 1200px;
$screen-tablet: 768px;
$screen-mobile: 320px;

// Desktop
$screen-desktop-max: $screen-desktop;
$screen-desktop-min: $screen-desktop;

// Tablet
$screen-tablet-max: $screen-desktop-min - 1px;
$screen-tablet-min: $screen-tablet;

// Mobile
$screen-mobile-max: $screen-tablet - 1px;
$screen-mobile-min: $screen-mobile;