@import "src/styles/colors";

.upload-default {
  &__dragger.ant-upload {
    height: 88px;
    overflow: hidden;
    border-radius: 8px;
    border: 0;
    transition: background-color .3s;

    &:before {
      pointer-events: none;
      content: "";
      position: absolute;
      border: 3px dashed $stroke;
      top: -2px;
      bottom: -2px;
      left: -2px;
      right: -2px;
      border-radius: 10px;
      transition: border-color .3s;
    }

    .upload-default__content {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      line-height: 20px;
      color: $main;

      .browse {
        color: $accent_70;
      }

      .upload-icon {
        margin-right: 4px;
        path {
          transition: .3s;
        }
      }
    }

    &:hover {
      background-color: $accent_20;
      &:before {
        border-color: $accent_70;
      }

      .upload-icon path {
        fill: $accent_70;
      }
    }
  }

  .typography-label {
    margin-bottom: 8px;
  }
}

.ant-upload-list:not(:empty) {
  margin-top: 12px;
}

.upload-default__item {
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;

  .file-icon {
    flex: 0 0 32px
  }

  .typography {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin:  0 52px 0 8px;
    color: $main;
  }

  .remove-file {
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 2px;
    right: 0;
    border-radius: $border_radius;

    &:hover {
      background-color: $accent_20;
    }

    path {
      fill: $accent_70;
    }
  }
}