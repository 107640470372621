@import "src/styles/colors";
@import "src/styles/breakpoints";
@import "src/styles/mixin";

.dashboard-service-head {
  width: 100%;
  max-width: 1144px;
  padding: 84px 0 32px;
  margin: 0 auto;

  &__tabs {
    margin-bottom: 4px;
  }

  .all-openings {

    thead {
      transition: none !important;
    }

    .self-submitted-candidates .ant-table-thead .ant-table-cell:nth-child(2) {
      padding-left: 44px !important;
    }

    &__empty {
      padding: 44px;
      svg {
        width: 124px;
        height: 124px;
      }

      .typography-h3 {
        margin-top: 40px;
        color: $main;
      }

      .typography-text {
        margin-top: 8px;
        color: $main;
      }
    }

    &__sub-table {
      margin-bottom: 0;

      .ant-table-cell {
        height: 72px;
      }

      .all-openings {
        &__job {
          a {
            display: block;
            color: $main;
            position: relative;

            &:hover {
              color: $accent_70;
            }

            .typography-text {
              @include multipleRowsEllipsis(2);
              padding-left: 32px;
            }

            .priority {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
        &__expected-date {
          .error {
            color: $error;
          }
        }
        &__location > div,
        &__team-lead > div {
          @include multipleRowsEllipsis(2);
        }
        &__status {
          .status-default {
            width: fit-content;
            white-space: break-spaces;
          }
        }
        &__action {
          display: flex;
          align-items: center;
          .ant-btn-primary {
            margin-left: 12px;
          }
        }
      }

      &.job {
        .all-openings__level {
          padding-left: 44px;
          position: relative;

          svg {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 12px;
          }
        }
      }
    }

    &__header-extra {
      display: flex;
      align-items: center;

      .select-default {
        margin: 0 24px 0 0;
        width: 320px;
        .select-default__tag {
          max-width: 260px; // will be removed next
        }
      }

      .ant-divider-vertical {
        height: 32px;
      }

      .filter-container {
        display: flex;
        align-items: center;

        .typography-caption {
          margin-right: 8px;
          color: $placeholder;
        }
      }
    }

    .ant-table-row-level-0 {
      height: 48px;
    }

    .expanded-row {
      width: 1144px;
      height: 48px;
      padding: 0 12px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $whitegray;
      border-bottom: 1px solid $border;

      &__info {
        padding-left: 32px;
        position: relative;
        text-align: left;

        .name {
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          cursor: pointer;

          span {
            margin-left: 8px;
            color: $lightgray;
          }

          &__extra {
            font-size: 12px;
            line-height: 16px;
            color: $placeholder;
          }
        }
      }

      &__extra {
        display: flex;
        align-items: center;

        .typography-caption {
          margin-left: 40px;
          color: $placeholder;
        }
      }

      &__icon {
        position: absolute;
        top: 50%;
        left: 12px;
        transform: rotate(180deg) translate(50%, 50%);
        cursor: pointer;
        &.expanded {
          transform: rotate(270deg) translate(50%, -50%);
        }
      }
    }
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
    padding: 168px 32px 16px;

    .all-openings__empty {
      width: calc(100vw - 64px);
    }
  }

  @media only screen and (max-width: $screen-mobile-max) {
    padding: 108px 0 8px;

    &__banner-wrapper {
      .top-banner {
        display: none;
      }

      .new-jobs {
        width: 100%;
        border-radius: 0;
      }
    }

    .all-openings {
      &__empty {
        width: 100vw;
        padding: 48px 16px;

        .typography-h3 {
          margin-top: 24px;
        }
      }

      .table-default__header {
        flex-direction: column;
        align-items: flex-start;

        .table-info {
          margin-bottom: 12px;
        }
      }
      &__header-extra {
        flex-direction: column;
        align-items: flex-start;

        .ant-divider {
          display: none;
        }

        .filter-container {
          margin-top: 8px;
        }
      }

      .ant-table-thead {
        display: none;
      }
      .ant-table-content {
        overflow: hidden;

        table {
          table-layout: unset !important;
        }

        .ant-table-cell {
          height: 100px;
        }

        .expanded-row {
          width: 100vw;
          height: 100px;
          padding: 16px 16px 12px;
          flex-direction: column;
          align-items: flex-start;

          &__extra {
            margin-top:4px;
            width: 100%;
            flex-direction: column;
            align-items: flex-start;

            .typography-caption {
              margin-left: 32px;
              width: auto;
              text-align: left;

              &:nth-child(2) {
                margin-top: 8px;
              }
            }
          }
        }
      }

      &.job {
        .ant-table-content {
          .ant-table-cell {
            height: 84px;
          }

          .expanded-row {
            height: 84px;
          }
        }

        .all-openings__level {
          padding-left: 32px;

          svg {
            left: 0;
          }
        }
      }
    }
    .ant-table-expanded-row-level-1 {
      .all-openings__sub-table {
        display: none;

        &-mobile {
          display: block;
        }
      }
    }
  }
}