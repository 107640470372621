@import "src/styles/colors";
@import "src/styles/breakpoints";
@import "src/styles/mixin";

.dashboard-recruiter-lead {
  width: 100%;
  max-width: 1144px;
  padding: 84px 0 32px;
  margin: 0 auto;

  &__tabs {
    margin-bottom: 4px;
  }

  &__banner-wrapper {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;

    .top-banner {
      margin-bottom: 0;
      width: 556px;
      height: 264px;
      border: 1px solid #E9EFFB;
      border-radius: 16px;
      background-image: url('../../dist/images/dashboard_banner556.svg');
      background-color: #E9EFFB;
      background-size: cover;
      background-position: center center;
      overflow: hidden;
    }
  }


  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
    padding: 168px 32px 16px;

    &__banner-wrapper {
      .top-banner {
        width: 224px;
        flex: 224px 0 0;
      }

      .new-jobs {
        margin-left: 32px;
        flex: 1;
      }
    }

    .table-extra, .active-candidates__header-extra {
      width: 100%;
    }

    .table-default__header {
      flex-direction: column;
      align-items: flex-start;

      .table-info {
        margin-bottom: 12px;
      }

      .recruiters-filter {
        margin-right: auto;
        max-width: 298px;
      }
    }
  }

    thead {
      transition: none !important;
    }

    .self-submitted-candidates .ant-table-thead .ant-table-cell:nth-child(2) {
      padding-left: 44px !important;
    }
  @media only screen and (max-width: $screen-mobile-max) {
    padding: 108px 0 8px;

    &__banner-wrapper {
      .top-banner {
        display: none;
      }

      .new-jobs {
        width: 100%;
        border-radius: 0;
      }
    }

    .ant-table-expanded-row-level-1 {
      .all-openings__sub-table {
        display: none;

        &-mobile {
          display: block;
        }
      }
    }
  }
}