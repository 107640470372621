@import "../../styles/colors";

.job-offer {

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .open-details {
      cursor: pointer;
      transform: rotate(-90deg);
    }
  }
  &__content {
    margin-top: 24px;
    position: relative;
    display: none;

    .edit-button {
      position: absolute;
      right: 0;
    }

    .months {
      margin-top: 32px;
    }

    .info-block {
      margin-bottom: 16px;

      .typography-label {
        margin-bottom: 4px;
        font-weight: 400;
        color: $lightgray;
      }

      .typography-text {
        color: $main;
      }
    }

    &-footer {
      display: flex;
      justify-content: flex-end;

      .button-default {
        margin-left: 16px;
      }
    }
  }

  .opening-default {
    margin-top: 32px;
  }

  .datepicker-default {
    width: 100%;
  }

  &.open {
    .job-offer__content {
      display: block;
    }

    .open-details {
      transform: rotate(90deg);
    }
  }

  .ant-divider {
    margin: 32px 0;
  }

  &__recommendation {
    margin-bottom: 24px;
    padding: 16px 32px;
    background: $disabled_bg;
    border-radius: 4px;

    .user-default {
      margin: 16px 0;
    }

    .typography-label {
      margin: 16px 0 4px;
      color: $lightgray;
      font-weight: 400;
    }

    .typography-text {
      word-wrap: break-word;
    }
  }
}