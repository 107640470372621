@import "src/styles/colors";
@import "src/styles/breakpoints";

.candidate-details {
  margin-right: 32px;
  padding: 24px;
  flex: 0 0 360px;
  max-width: 360px;
  box-shadow: $box_shadow_level_3;

  &__header {
    .candidate-name {
      margin-bottom: 24px;

      .typography-h2 {
        margin: 0 0 4px;
      }

      .typography-h4 {
        margin: 0 0 4px;
        color: $lightgray;

        &:empty {
          display: none;
        }
      }

      &__loader {
        margin-bottom: 24px;
      }
    }
  }

  &__block {
    margin-bottom: 24px;
    .info-item {
      display: flex;
      align-items: center;
      margin-top: 8px;
      &__icon {
        flex: 0 0 16px;
        margin-right: 8px;
      }
    }

    &:last-child {
      margin: 0;
    }
  }

  &__employees {
    margin-top: 8px;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;

    &:empty {
      display: none;
    }

    .ant-btn-primary {
      flex: 1 0;
      margin-right: 16px;
    }
    li.error {
      color: $error !important;
    }
    .print-cv {
      width: 100%;
    }
  }
  &__link {
    margin-right: 12px;
    cursor: pointer;
    &__tooltip .ant-tooltip-inner {
      max-width: 240px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .typography-label {
    margin-bottom: 8px;
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
    margin: 0 0 16px;
    max-width: 100%;

    &__header {
      margin: 0;
      display: flex;
      justify-content: space-between;

      .candidate-name {
        margin: 0;
      }
    }
  }

  @media only screen and (max-width: $screen-mobile-max) {
    margin: 0;
    max-width: 100%;
    padding: 24px 16px 32px 16px;
    border-radius: 0;
    box-shadow: none;
  }
}

.social-network {
  display: flex;
  align-items: center;

  &__icon {
    width: 16px;
    height: 16px;
  }
}

.delete-candidate-popup {
  &__local-name {
    margin-top: 4px;
  }
  &__warning {
    margin-top: 24px;
  }
}