@import "src/styles/colors";
@import "src/styles/breakpoints";

.jobs-filters {
  margin-right: 32px;
  padding: 24px;
  width: 360px;
  height: min-content;

  &__header {
    display: none;
    margin: 0 -24px 24px;
    height: 64px;
    padding: 0 36px;
    align-items: center;
    justify-content: space-between;
    box-shadow: inset 0px -1px 0px $border;

    .open-details {
      svg {
        transform: rotate(-90deg);
        path {
          stroke: $accent_70;
        }
      }

      &.open svg {
        transform: rotate(-270deg);
      }
    }
  }

  &__footer {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;

    .button-default {
      flex: 1;
      &:last-child {
        margin-left: 16px;
      }
    }
  }

  .hidden_field {
    display: none;
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
    margin: 0 32px 12px 0;
    width: 100%;
    padding-top: 0;

    &__header {
      padding: 0 12px 0 24px;
      display: flex;
    }

    &:not(.open) {
      padding-bottom: 0;
      .jobs-filters__header {
        margin-bottom: 0;
        box-shadow: none;
      }

      .jobs-filters__body {
        display: none;
      }
    }

    &__footer {
      justify-content: flex-end;

      .button-default {
        flex: unset;
      }
    }
  }

  @media only screen and (max-width: $screen-mobile-max) {
    margin: 0 0 12px;
    width: 100%;
    padding: 0 16px 16px;
    border-radius: 0;

    &__header {
      display: flex;
      margin: 0 -16px 24px;
      padding: 0 16px;
    }

    &:not(.open) {
      padding-bottom: 0;
      .jobs-filters__header {
        margin-bottom: 0;
        box-shadow: none;
      }

      .jobs-filters__body {
        display: none;
      }
    }

    &__footer {
      margin-top: 8px;
      .button-default:last-child {
        margin-left: 12px;
      }
    }
  }
}