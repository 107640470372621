$black: #000000;
$blackblue: #181B21;
$main: #393F4A;
$darkgray: #505966;
$lightgray: #97A3B5;
$placeholder: #717C8C;
$stroke: #CAD3E0;
$disabled_text: #AFBBCC;
$whitegray: #FCFCFC;
$disabled_bg: #F5F7FA;
$logo_color: #1F1D1D;
$accent_100: #821F00;
$accent_90: #B72C00;
$accent_80: #E13800;
$accent_70: #FC4C02;
$accent_60: #FF611E;
$accent_50: #FF9D5B;
$accent_40: #FFBC88;
$accent_30: #FFDABB;
$accent_20: #FFF1E5;
$white: #FFFFFF;
$border: #E1E7F0;
$grey100: #222429;

$success: #288840;
$error: #FF3C3C;

$box_shadow_header: 0 1px 2px rgba(0, 0, 0, 0.1);
$box_shadow_card: 0 0.3px 0.5px rgba(80, 89, 102, 0.1), 0 2px 4px rgba(80, 89, 102, 0.2);
$box_shadow_fixed_panel: 0px -1px 2px rgba(0, 0, 0, 0.15);
$main_shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
$box_shadow_tooltip: 0 1px 11px rgba(0, 0, 0, 0.12);
$card_view_shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);

$border_radius: 4px;
$border_radius_card: 8px;

$box_shadow_level_1: 1px solid rgba(80, 89, 102, 0.12);
$box_shadow_level_2: 0px 0.1px 0.3px rgba(80, 89, 102, 0.1), 0px 1px 2px rgba(80, 89, 102, 0.2);
$box_shadow_level_3: 0px 0.3px 0.5px rgba(80, 89, 102, 0.1), 0px 2px 4px rgba(80, 89, 102, 0.2);
$box_shadow_level_4: 0px 0.4px 0.8px rgba(80, 89, 102, 0.1), 0px 3px 6px rgba(80, 89, 102, 0.2);
$box_shadow_level_5: 0px 0.5px 1px rgba(80, 89, 102, 0.1), 0px 4px 8px rgba(80, 89, 102, 0.2);
$box_shadow_level_6: 0px 0.8px 1.5px rgba(80, 89, 102, 0.1), 0px 6px 12px rgba(80, 89, 102, 0.2);
$box_shadow_level_7: 0px 1px 2px rgba(80, 89, 102, 0.1), 0px 8px 16px rgba(80, 89, 102, 0.2);
$box_shadow_level_8: 0px 1.5px 3px rgba(80, 89, 102, 0.1), 0px 12px 24px rgba(80, 89, 102, 0.2);
$box_shadow_level_9: 0px 2px 4px rgba(80, 89, 102, 0.1), 0px 16px 32px rgba(80, 89, 102, 0.2);
$box_shadow_level_10: 0px 3px 6px rgba(80, 89, 102, 0.1), 0px 24px 48px rgba(80, 89, 102, 0.2);
