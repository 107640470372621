@import 'src/styles/colors.scss';

.button-default {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    display: none;
  }

  svg {
    margin: 0;
    display: block;
    height: 16px;
    width: 16px;
    & ~ span {
      margin-left: 4px;
    }
  }
  &:not(.original-svg):active svg {
    path {
      fill: $accent_80;
    }
  }

  &:not(.original-svg):disabled {
    svg path {
      fill: $disabled_text;
    }
  }

  & > span {
    font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.01em;
    font-size: 12px;
  }

  &.ant-btn-icon-only {
    padding: 10px;
  }

  .ant-btn-loading-icon {
    display: flex;
    align-items: center;
  }

  &.ant-btn-primary {
    border: 1px solid $accent_70;
    &:focus {
      background-color: #FF803C;
      border-color: #FF803C;
    }
    &:hover {
      background-color: $accent_60;
      border-color: $accent_60;
    }
    &.active,
    &:active {
      background-color: $accent_80;
      border-color: $accent_80;
    }
    &:disabled {
      &, &:focus, &:hover, &:active {
        background-color: $disabled_bg;
        border-color: transparent;
        color: $disabled_text;
      }
    }
  }

  &.ant-btn-default {
    border: 1px solid $accent_70;
    color: $accent_70;
    &:focus {
      background-color: $accent_20;
      border-color: $accent_60;
      color: $accent_60;
    }
    &:hover {
      background-color: $accent_20;
      border-color: $accent_70;
      color: $accent_70;
    }
    &.active,
    &:active {
      background-color: $accent_30;
      border-color: $accent_80;
      color: $accent_80;
    }
    &:disabled {
      &, &:focus, &:hover, &:active {
        background-color: $disabled_bg;
        border-color: $disabled_text;
        color: $disabled_text;
      }
    }
  }

  &.ant-btn-ghost {
    color: $accent_70;
    border-color: transparent;
    &:focus {
      background-color: $white;
      border-color: #FF803C;
      color: $accent_60;
    }
    &:hover {
      background-color: $accent_20;
    }
    &.active,
    &:active {
      background-color: $accent_20;
      border-color: $accent_20;
      color: $accent_80;
    }
    &:disabled {
      &, &:focus, &:hover, &:active {
        background-color: transparent;
        border-color: transparent;
        color: $disabled_text;
      }
    }
  }

  &.ant-btn-text {
    height: 20px;
    padding: 2px 4px;
    color: $accent_70;
    border-color: transparent;
    background-color: transparent;
    &:focus {
      background-color: $white;
      border-color: #FF803C;
      color: $accent_60;
    }
    &:hover {
      color: $accent_60;
    }
    &.active,
    &:active {
      background-color: transparent;
      border-color: transparent;
      color: $accent_80;
    }
    &:disabled {
      &, &:focus, &:hover, &:active {
        background-color: transparent;
        border-color: transparent;
        color: $disabled_text;
      }
    }
  }
}