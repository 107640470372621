.form-job-creation {
  width: 100%;
  padding: 24px;

  .typography-h2 {
    margin-bottom: 20px;
    font-weight: 600;
  }

  &__form-item {
    margin-bottom: 16px;
    width: 100%;
    .rich-area-default {
      margin: 0;
    }

    &.ant-form-item-has-error {
      margin: 0;
      padding-bottom: 32px;
    }
  }

  &__team {
    margin-top: 40px;
  }
}