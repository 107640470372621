@import "src/styles/colors";

.notification-default {
  padding: 8px;
  min-height: 40px;
  background-color: $blackblue;
  border-left: 4px solid transparent;
  box-shadow: none;

  &.error {
    border-color: $error;
  }
  &.warning {
    border-color: #FFB500;
  }
  &.success {
    border-color: #22B44B;
  }

  .ant-notification-notice-close {
    top: 8px;
    right: 8px;
  }
  .ant-notification-notice-icon {
    margin: 0;
    top: -2px;
    svg {
      display: block;
    }
  }

  .ant-notification-notice-content {
    position: relative;

    .ant-notification-notice-message {
      margin: 2px 0 2px 40px;
      padding-right: 56px;
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      line-height: 20px;
      color: $white;
    }
  }
  .ant-notification-notice-btn {
    margin-top: 8px;
    margin-left: 40px;
    float: left;
    font-weight: 600;
    color: $white;
    cursor: pointer;
  }
  .close-notification {
    display: block;
    path {
      stroke: $white;
    }
  }
}