@import 'src/styles/colors';
@import "src/styles/breakpoints";

.form-list-sources {
  margin-bottom: 16px;
  &__item {
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 12px;
    }

    &:first-child .form-list-sources__item__second {
      padding-top: 24px;
    }

    &__delete {
      path {fill: $accent_70;}
      &:focus path {fill: $accent_60}
      &:active path {fill: $accent_80}
    }
    .select-default {
      margin-bottom: 0;
    }
    .input-default-wrapper {
      margin-top: 8px;
      padding: 0;
    }

    &:first-child.with-label .form-list-double-select__item__second button {
      margin-top: 24px;
    }

    &:first-child:not(.with-label) .form-list-double-select__item__second {
      margin-top: 24px;
    }
  }
  .typography-label {
    margin-bottom: 8px;
  }
  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {}

  @media only screen and (max-width: $screen-mobile-max) {
    &__item {
      flex-direction: column;

      &__second {
        margin-top: 8px;
      }
    }
  }
}