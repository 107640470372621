@import "src/styles/colors";

.analytics-openings {
  .export-excel {
    margin: 32px 0 16px auto;
  }

  &__job-name,
  &__job-name span,
  &__team-lead,
  &__team-lead span,
  &__office-location,
  &__office-location span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  tbody .analytics-openings__potential-candidates,
  tbody .analytics-openings__assigned-candidates-count {
    font-weight: 600 !important;
    text-align: right;
  }

  &__empty {
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      padding: 16px 12px;
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
      color: #181B21;
      width: 100%;
      border-bottom: 1px solid #E1E7F0;
    }

    svg {
      margin: 40px 0;
      width: 124px;
      height: 124px;
    }

    h3 {
      margin-bottom: 8px;
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
      color: #393F4A;
    }

    p {
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
      color: #717C8C;
    }
  }
}