@import "src/styles/colors";
@import "src/styles/breakpoints";

.job-view {
  display: block;
  width: 100%;

  .bread-crumbs {
    padding-top: 0;

    &__wrapper {
      position: static;
    }
  }

  .jobs-menu-button-wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;

    .button-default {
      position: absolute;
      right: 24px;
      bottom: 20px;
    }
  }

  .job-card {
    padding: 24px 0 0;
    margin-bottom: 8px;
    .job-title-wrapper {
      margin-bottom: 15px;
      &.jobs{
        .job-title{
          margin-right: 12px;
        }
        .ats-title-wrapper{
          .title{
            font-size: 15px;
            line-height: 18px;
            color: #707E8B;
          }

        }
      }
      &_service{
        .title{
          margin-bottom: 0;
          color: #757575
        }
      }
      &_nameStatus{
        display: flex;
        margin-bottom: 5px;
        align-items: center;
        //*{
        //  display: inline-block;
        //}
      }
    }

    .job-title {
      margin-right: 20px;
      color: $black;
      font-size: 22px;
      font-weight: 600;
    }

    .tab-menu-wrapper {
      position: relative;
      padding-left: 9px;
    }

    .job-info-header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 25px;
      padding-left: 24px;
      padding-right: 24px;

      .job-manager-wrapper {
        position: relative;
        top: 5px;
        &.label-info{
          .content-name{
            line-height: 22px;
            color: $black;
            margin-bottom: 0;
          }
          .content-position{
            color: #707E8B;
          }
        }

        .label-wrapper {
          display: flex;
          justify-content: space-between;
          position: relative;
          .list-of-recruiters{
            position: absolute;
            z-index: 6;
            background: $white;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
            border-radius: $border_radius;
            top: 45px;
            ul{
            padding-left: 0;
              li{
                padding: 0 20px;
                min-width: 226px;
                display: flex;
                list-style: none;
                justify-content: space-between;
                align-items: center;
                min-height: 44px;
                .delete_icon{
                  display: none;
                }
                .assign{
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 18px;
                  color: #FB5830;
                }
                &:hover{
                  cursor: pointer;
                  background-color: #F5F6F7;
                  .delete_icon{
                    display: inherit;
                  }
                }
                .ats-avatar-label{
                  padding: 6px 0;
                  border: none;
                  .content-name{
                    font-size: 13px;
                    line-height: 18px;
                    color: $black;

                  }
                  .content-position{
                    font-size: 13px;
                    line-height: 18px;
                    color: #707E8B;

                  }
                }
              }
            }
          }
          .assign-recruiter{
            margin-top: 4px;
            font-weight: normal;
            font-size: 13px;
            line-height: 18px;
            text-decoration-line: underline;
            color: $accent_70;
            &:hover, &:active{
              color: #FB5830;
              cursor: pointer;
              text-decoration: none;
            }

          }
          .multiple-recruiters{
            display: flex;
            padding-right: 20px;
            border-right: 1px solid #eff1f4;
            margin-right: 20px;

            .ats-count-circle{
              border: none;
              color: #707E8B;
              background: #E6E8EB;
            }
            &__titles{
              margin-left: 10px;
              display: flex;
              flex-direction: column;
              margin-top: -2px;
            }

            &__title{
              font-weight: normal;
              font-size: 13px;
              line-height: 18px;
              color: $black;

            }
            &__subtitle{
              font-weight: normal;
              font-size: 13px;
              line-height: 18px;
              text-decoration-line: underline;
              color: #707E8B;
              margin-top: 2px;
              background: none;
              text-align: start;
              outline: none;
              padding: 0;
              border: none;
                &:hover, &:active{
                  color: #FB5830;
                  cursor: pointer;
                  text-decoration: none;
                }
            }
          }

          .ats-avatar-label {
            padding-right: 20px;
            margin-right: 20px;

            &:first-child {
              border-right: 1px solid #EFF1F4;
            }
          }
        }
      }
    }
  }
}
