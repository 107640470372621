.steps-empty {
  height: 456px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  &__image {
    margin-bottom: 40px;
    width: 124px;
    height: 124px;
  }

  &__title {
    margin-bottom: 8px;
  }

  &__text {
    max-width: 424px;
  }

  &__button {
    margin-top: 20px;
  }
}