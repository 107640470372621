@import 'src/styles/colors';

.tooltip-default {
  padding-bottom: 0;

  & *:not(button):not(button span) {
    color: #fff !important;
  }

  .ant-tooltip-arrow {
    display: none;
  }
  .ant-tooltip-inner {
    padding: 6px 12px;
    min-height: auto;

    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    line-height: 16px;

    .rich-area-default__rich-content {
      max-height: 50vh;
      overflow-y: auto;
    }
  }

  &.white {
    .ant-tooltip-inner {
      background-color: $white;
      color: $black !important;
    }
  }
}
