@import "../../styles/colors";
@import "../../styles/breakpoints";

.job-description {
  padding: 24px 40px;
  margin-bottom: 0;

  &__general {
    margin-bottom: 32px;

    .typography-label {
      margin-bottom: 8px;
    }

    .gray-label {
      margin-bottom: 4px;
      font-weight: 400;
      color: $lightgray;
    }

    .margin-description {
      margin-bottom: 16px;

      &__item {
        margin-bottom: 16px;
      }
    }

    .benefits {
      margin-bottom: 32px;
    }
  }

  &__team {
    .typography-label {
      margin-bottom: 12px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }

    .margin-user {
      margin-bottom: 24px;
    }
  }

  &__recruiters {
    row-gap: 24px;
  }

  .typography-h2 {
    margin-bottom: 24px;
  }

  .empty-recruiters {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $lightgray;
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
    padding: 24px;

    &__team {
      .typography-label {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  @media only screen and (max-width: $screen-mobile-max) {
    padding: 24px;
    border-radius: 0;
    box-shadow: none;

    .margin-description,
    &__rm-sh,
    &__recruiters {
      row-gap: 16px;
      flex-direction: column;
    }

    .benefits {
      margin-bottom: 36px;
    }

    &__team {
      .typography-label {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}