@import "src/styles/colors";
@import "src/styles/breakpoints";

.feedback-form {
  &__decision {
    .ant-radio-group,
    .ant-form-item {
      width: 100%;

      &:nth-child(2) {
        display: none;
      }
    }
    .ant-radio-button-wrapper {
      width: 50%;
      text-align: center;
    }

    &-info {
      margin-top: 34px;
    }
  }

  .rich-area-default {
    margin-bottom: 8px;
  }

  &__user {
    margin-bottom: 16px;
    max-width: 50%;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;

    .ant-btn-default {
      margin-right: 16px;
    }
  }
}

.feedback-info {
  .typography-label {
    margin: 16px 0 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $lightgray;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    >div {
      display: flex;
      max-width: 50%;
    }

    .created-at {
      margin: 0;
      font-weight: 400;
      color: $darkgray;
    }
  }

  &__info {
    display: flex;
    align-items: center;

    .status-default {
      margin-right: 16px;
    }

    .button-default {
      margin-left: 32px;
    }
  }

  &__content {
    padding-left: 44px;
  }

  .actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;

    .ant-btn-default {
      margin-right: 16px;
    }
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
  }

  @media only screen and (max-width: $screen-mobile-max) {
    &__header {
      flex-direction: column;
      gap: 16px;

      > div {
        max-width: 100%;
      }
    }

    &__info {
      width: 100%;
      flex-direction: column-reverse;
      align-items: flex-start;
      gap: 8px;
      position: relative;

      .button-default {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &__content {
      padding: 0;
    }

    .actions {
      margin-top: 0;
      flex-direction: column;

      .button-default {
        margin: 16px 0 0;
      }
    }
  }
}

.job-interview-feedback {

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .open-details {
      cursor: pointer;
      transform: rotate(-90deg);
    }
  }

  &__content {
    margin-top: 24px;
    display: none;

    > *:not(:first-child) {
      margin-top: 24px;
    }

    .all-feedbacks {
      padding: 0;
      height: auto;
      background-color: $white !important;
      border-color: transparent !important;

      svg {
        transform: rotate(-90deg);
        margin: 0 0 0 4px;
        path {
          stroke: $accent_70;
        }
      }

      &:active svg path {
        stroke: $accent_80;
        fill: none;
      }

      &.open svg {
        transform: rotate(90deg);
      }
    }
  }
  &.open {
    .job-interview-feedback__content {
      display: block;
    }

    .open-details {
      transform: rotate(90deg);
    }
  }

  .ant-divider {
    margin: 32px 0;
  }
}