@import 'src/styles/colors';
@import 'src/styles/breakpoints';

.bread-crumbs {
  width: 1144px;
  padding: 12px 0;
  display: flex;
  align-items: center;
  background-color: $whitegray;

  &__wrapper {
    width: 100%;
    background: $whitegray;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 68px;
    left: 0;
    z-index: 9;
  }

  &__wrapper {
    width: 100%;
    background: $whitegray;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 68px;
    left: 0;
    z-index: 9;
  }

  &_element {
    color: $accent_70;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    text-decoration: none;
    line-height: 16px;
    &:hover{
      color: $accent_80;
    }
  }
  &_name {
    color: $placeholder;
    font-family: 'Open Sans', sans-serif;
  }
  &_arrow {
    width: 16px;
    height: 16px;
    transform: rotate(180deg);
    margin: 0 4px;
    path {
      stroke: $disabled_text;
    }
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {}

  @media only screen and (max-width: $screen-mobile-max) {
    padding: 12px 16px;
  }
}