@import "../../styles/breakpoints";

.typography {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;

  &-h1 {
    font-size: 22px;
    line-height: 26px;
  }
  &-h2 {
    font-size: 18px;
    line-height: 24px;
  }
  &-h3 {
    font-size: 16px;
    line-height: 20px;
  }
  &-h4 {
    font-size: 14px;
    line-height: 20px;
  }
  &-text {
    font-size: 14px;
    line-height: 20px;
  }
  &-caption {
    font-size: 12px;
    line-height: 16px;
  }
  &-label {
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
  }
  &-numbers-large {
    font-size: 64px;
    line-height: 72px;
  }
  &-numbers-medium {
    font-size: 40px;
    line-height: 48px;
  }

  &.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.bold {
    font-weight: 600;
  }
  &.normal {
    font-weight: 400;
  }
  &.light {
    font-weight: 200;
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
    &-h1 {
      font-size: 20px;
      line-height: 24px;
    }
    &-h2 {
      font-size: 16px;
      line-height: 20px;
    }
    &-h3 {
      font-size: 14px;
      line-height: 16px;
    }
    &-h4 {
      font-size: 12px;
      line-height: 16px;
    }
    &-text {}
    &-caption {}
    &-label {}
    &-numbers-large {
      font-size: 40px;
      line-height: 48px;
    }
    &-numbers-medium {
      font-size: 34px;
      line-height: 42px;
    }
  }

  @media only screen and (max-width: $screen-mobile-max) {
    &-h1 {
      font-size: 18px;
      line-height: 24px;
    }
    &-h2 {
      font-size: 16px;
      line-height: 20px;
    }
    &-h3 {
      font-size: 14px;
      line-height: 16px;
    }
    &-h4 {
      font-size: 12px;
      line-height: 16px;
    }
    &-text {}
    &-caption {}
    &-label {}
    &-numbers-large {
      font-size: 34px;
      line-height: 42px;
    }
    &-numbers-medium {
      font-size: 22px;
      line-height: 26px;
    }
  }
}
