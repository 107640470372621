@import "src/styles/colors";

.not-found-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .not-found-card {
    width: 556px;
    height: 570px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: url("../../dist/img/404-desktop.svg") center top -2px no-repeat $white;

    .typography-h3 {
      margin-bottom: 16px;
      max-width: 342px;
    }

    .typography-text {
      margin-bottom: 40px;
      max-width: 282px;
    }

    button {
      width: max-content;
    }
  }
}