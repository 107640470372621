@import "src/styles/colors";
@import "src/styles/breakpoints";

.opening-creation {
  padding: 24px;
  &__header {
    margin: 0 auto;
    padding: 24px;
    width: 1144px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-wrapper {
      width: 100%;
      position: fixed;
      top: 108px;
      left: 0;
      z-index: 9;
      background-color: $whitegray;
    }

    .submit-buttons {
      display: flex;

      .ant-btn-primary {
        margin-left: 16px;
      }
    }
  }

  &__wrapper.wrapper-content {
    max-width: 1144px;
    padding: 204px 0 16px;
    display: block;
  }

  &__title {
    margin-bottom: 20px;
  }

  .employment-type {
    margin-bottom: 40px;
  }

  .required-work-experience {
    .ant-radio-button-wrapper {
      min-width: 86px;
      text-align: center;
    }
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
    &__wrapper {
      &.wrapper-content {
        padding: 272px 32px 32px;
      }
      .bread-crumbs__wrapper {
        padding: 0 32px 32px;
        top: 136px;
      }
    }
    &__header {
      width: calc(100vw - 70px);
      &-wrapper {
        top: 176px;
      }
    }

    .employment-type {
      .button-group-default__group-wrapper {
        & > * {
          width: 50%;
        }
        .input-default-wrapper {
          margin: 0;
          padding-left: 16px;
        }
      }
      .ant-radio-group {
        padding-right: 16px;
        width: 100%;
        .ant-radio-button-wrapper {
          width: 33.33%;
          min-width: unset;
          text-align: center;
        }
      }
    }

    .required-work-experience {
      .button-group-default__group-wrapper {
        & > * {
          width: 50%;
        }
        .input-default-wrapper {
          margin-left: 16px;
        }
      }
      .ant-radio-group {
        padding-right: 16px;
        width: 100%;
        .ant-radio-button-wrapper {
          min-width: unset;
          width: 25%;
        }
      }
    }
  }
  @media only screen and (max-width: $screen-mobile-max) {
    padding: 24px 16px;
    border-radius: 0;

    &__wrapper {
      &.wrapper-content {
        padding: 252px 0 0;
      }
      .bread-crumbs__wrapper {
        padding: 0;
        top: 92px;
      }
    }
    &__header {
      width: 100%;
      padding: 16px 16px 12px;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 0;
      box-shadow: $card_view_shadow;

      &-wrapper {
        top: 132px;
      }

      .submit-buttons {
        margin-top: 16px;
        width: 100%;
        button {
          flex: 1;
        }
      }
    }

    .datepicker-default {
      width: 100%;
    }

    .required-experience-level {
      flex-direction: column;
    }

    .employment-type {
      margin-bottom: 32px;

      .button-group-default__group-wrapper {
        flex-direction: column;

        & > * {
          width: 100%;
        }
        .input-default-wrapper {
          margin: 8px 0 0 0;
          width: 100%;
        }
      }
      .ant-radio-group {
        width: 100%;
        .ant-radio-button-wrapper {
          width: 33.33%;
          min-width: unset;
          text-align: center;
        }
      }
    }

    .required-work-experience {
      .button-group-default__group-wrapper {
        flex-direction: column;

        & > * {
          width: 100%;
        }
        .input-default-wrapper {
          margin: 8px 0 0 0;
          width: 100%;
        }
      }
      .ant-radio-group {
        width: 100%;
        .ant-radio-button-wrapper {
          min-width: unset;
          width: 25%;
        }
      }
    }
  }
}