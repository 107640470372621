@import "src/styles/colors";
@import "src/styles/breakpoints";

.resolution {

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .open-details {
      cursor: pointer;
      transform: rotate(-90deg);
    }
  }
  &__content {
    margin-top: 24px;
    position: relative;
    display: none;

    .edit-button {
      position: absolute;
      top: 0;
      right: 0;
    }

    .months {
      margin-top: 32px;
    }

    .info-block {
      margin-bottom: 16px;

      .typography-label {
        margin-bottom: 4px;
        font-weight: 400;
        color: $lightgray;
      }

      .typography-text {
        color: $main;
      }
    }

    &-footer {
      display: flex;
      justify-content: flex-end;

      .button-default {
        margin-left: 16px;
      }
    }
  }

  &.resolution-loading .resolution__header {
    margin-bottom: 24px;
  }

  .opening-default {
    margin-top: 32px;

    &__body__extra-info {
      margin-left: 0;
    }
  }

  .datepicker-default {
    width: 100%;
  }

  &.open {
    .resolution__content {
      display: block;
    }

    .open-details {
      transform: rotate(90deg);
    }
  }

  .ant-divider {
    margin: 32px 0;
  }



  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
  }
  @media only screen and (max-width: $screen-mobile-max) {
    &__content {
      .info-block {
        margin-bottom: 8px;
      }

      .edit-button {
        margin-top: 16px;
        width: 100%;
        position: static;
      }

      .opening-default {
        margin-top: 16px;
        padding: 0;
        border-radius: 0;
        box-shadow: none;

        &__buttons {
          display: none;
        }
      }
    }

    &__content-footer {
      .button-default {
        margin: 0;
        flex: 1;
        &[type="submit"] {
          margin-left: 16px;
        }
      }
    }
  }
}