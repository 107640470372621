@import 'src/styles/colors';

.form-list {
  margin-bottom: 16px;
  &__item {
    margin-bottom: 16px;
    &:last-of-type {
      margin-bottom: 12px;
    }

    &__delete {
      path {fill: $accent_70;}
      &:focus path {fill: $accent_60}
      &:active path {fill: $accent_80}
    }
    .select-default {
      margin-bottom: 0;
    }
    .input-default-wrapper {
      padding: 0;
    }
  }
  .typography-label {
    margin-bottom: 8px;
  }
}