@import "src/styles/colors";

.popup-reassign-recruiter {
  &__user {
    margin-bottom: 8px;
  }

  &__select {
    margin-bottom: 4px;
  }

  &__assign-to-me {
    padding: 0;
    height: unset;
    background-color: $white !important;
    border-color: transparent !important;
  }
}