@import "../../styles/breakpoints.scss";
@import '../../styles/colors.scss';

.avatar-default {
  border-radius: 200px;
  overflow: hidden;

  .blur {
    background: url('../../dist/icons/userpic.svg') no-repeat;
    width: 100%;
    height: 100%;
  }

  &.bordered {
    border: 1px solid $border;
    box-sizing: content-box;
  }

  img {
    object-fit: cover;
  }

  .img-avatar {
    background-repeat: no-repeat;
    background-size: cover;
  }

  img, svg, .img-avatar {
    width: 100%;
    height: 100%;
  }

  &.large {
    width: 64px;
    height: 64px;
  }

  &.normal {
    width: 32px;
    height: 32px;
  }


  &.small, &.extra-small {
    width: 24px;
    height: 24px;
  }
}
