@import "../../styles/colors";
@import "../../styles/breakpoints";

.container-steps {
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .current-step {
    display: none;
  }

  .step-back {
    height: 40px;
    position: absolute;
    width: 137.33px;
    white-space: nowrap;
    transition: 0.6s ease-out;
    top: 4px;
    left: 5px;
    background-color: $accent_70;
    border-radius: 4px 0 0 4px;
    display: inline-block;
    z-index: 1;

    &[data-step='1'] {
      left: calc(169.33px * 1 + 4px);
    }

    &[data-step='2'] {
      left: calc(169.33px * 2 + 4px);
    }

    &[data-step='3'] {
      left: calc(169.33px * 3 + 4px);
    }

    &[data-step='4'] {
      left: calc(169.33px * 4 + 4px);
    }

    &[data-step='5'] {
      left: calc(169.33px * 5 + 4px);
    }

    &[data-step='6'] {
      left: calc(169.33px * 6 + 4px);
      width: 120px;
      border-radius: $border_radius;
      .corner-img {
        display: none;
      }
    }

    .corner-img {
      position: absolute;
      right: 1px;
      transform: translateX(100%);
    }
  }

  .steps-panel {
    margin-bottom: 16px;
    padding: 4px;
    width: 1144px;
    height: 48px;
    display: flex;
    position: relative;
    box-shadow: 0 0.4px 0.8px rgba(80, 89, 102, 0.1), 0 3px 6px rgba(80, 89, 102, 0.2);

    .step {
      position: relative;
      display: flex;
      align-items: center;
      flex: 1;
      transition: 0.6s ease-out;

      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: $main;

      &__content {
        height: 40px;
        margin-right: 14px;
        padding: 6px 0 6px 12px;
        border-radius: 4px 0 0 4px;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .corner-img {
          position: absolute;
          right: 1px;
          transform: translateX(100%);
          path {
            fill: $white;
          }
        }

        &:hover {
          .corner-img {
            path {
              fill: #F5F7FA;
            }
          }
        }
      }

      &__label {
        z-index: 1;
        max-width: 90px;
      }

      &__count {
        z-index: 1;
        color: $placeholder;
        height: 24px;
        width: 24px;
        line-height: 24px;
        font-weight: 600;
        letter-spacing: 0.01em;
        text-align: center;
        background-color: $border;
        border-radius: 50%;
      }

      &__divider {
        margin: 0 4px;
      }

      &:not(.active) .step__content:hover {
        background-color: #F5F7FA;
        cursor: pointer;
      }

      &:last-child {
        flex: 0 0 120px;
        .step__content {
          margin-right: 0;
          padding-right: 8px;
          border-radius: $border_radius;
        }
        .step__divider, .corner-img {
          display: none;
        }
      }

      &.active {
        color: $white;
        transition: 0.6s ease-out;
        cursor: default;

        .step__count {
          background: $accent_80;
          color: $white;
        }
      }
    }
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
    margin-left: -32px;
    width: calc(100% + 64px);
    padding: 0 32px;
    overflow: auto;

    .step-back {
      transform: translateX(32px);
    }
  }

  @media only screen and (max-width: $screen-mobile-max) {
    overflow: unset;

    .step-back {
      display: none;
    }

    .current-step {
      margin: 0 16px 12px;
      padding: 4px;
      display: flex;
      align-items: center;
      .step {
        margin-right: 14px;
        height: 40px;
        padding-left: 12px;
        justify-content: space-between;
        border-radius: 4px 0 0 4px;
        background-color: $accent_70;
        color: $white;
        position: relative;
        display: flex;
        align-items: center;
        flex: 1 1;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;

        &__count {
          z-index: 1;
          height: 24px;
          width: 24px;
          line-height: 24px;
          font-weight: 600;
          letter-spacing: 0.01em;
          text-align: center;
          border-radius: 50%;
          background-color: $accent_80;
          color: $white;
        }

        &__divider {
          margin: 0 4px;
          flex: 0 0 10px;
        }

        .corner-img {
          position: absolute;
          right: -13px;
        }
      }

      &.last .step {
        &:after {
          content: " ";
          position: absolute;
          left: 100%;
          width: 24px;
          height: 40px;
          border-radius: 0 4px 4px 0;
          background-color: $accent_70;
        }
      }
    }

    .steps-panel {
      margin: 0 12px;
      width: calc(100% - 32px);
      padding: 4px;
      height: auto;
      flex-direction: column;
      display: none;
      position: absolute;
      z-index: 1;

      &.visible {
        display: flex;
      }

      .step {
        &.active {
          color: $main;
          .step__content {
            .step__count {
              background-color: $accent_40;
              color: $main;
            }
            background-color: $accent_20;
            .corner-img {
              path {
                fill: $accent_20;
              }
            }
          }
        }
        &:last-child {
          flex: none;

          .step__content {
            width: 100%;
            padding-right: 14px;
          }
        }
        &__content {
          margin: 0;
          width: calc(100% - 14px);
          flex: none;
          .step__count {
            color: $main;
          }
        }
        &__label {
          max-width: 100%;
        }
        :not(.current-step) .step__divider {
          display: none;
        }
      }
    }
  }
}
