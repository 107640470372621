.analytics-filter {
  padding: 24px;
  margin-bottom: 12px;
  .ats-select-wrapper {
    .ats-select--is-disabled {
      .ats-select__multi-value {
        background-color: hsl(0,0%,90%);
        color: #B3BBC4;

        .ats-select__multi-value__label {
          color: #B3BBC4;
        }
      }
    }
  }

  &.self-submitted-candidates {
    #analytics-filters {
      position: relative;
    }

    .button-footer {
      position: absolute;
      bottom: 16px;
      right: 0;
    }
  }

  .no-periods-error {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
    height: 40px;
    padding: 8px 12px;
    background-color: #FFEBEB;
    border-bottom: 1px solid #FF3C3C;
    color: #FF3C3C;
  }
}