@import "../../styles/colors";

.popup-close-opening {
  &__title {
    margin-bottom: 4px;
    color: $main;
  }
  &__subtitle {
    margin-bottom: 24px;
    color: $lightgray;
  }
}