@import "src/styles/colors";

.input-default {
  padding: 7px 10px;
  height: 36px;
  box-shadow: none !important;
  font-family: 'Open Sans', sans-serif;
  line-height: 20px;

  &::placeholder {
    color: $lightgray;

    font-family: 'Open Sans', sans-serif;
    line-height: 20px;
  }

  &:hover,
  &.ant-input-affix-wrapper:hover {
    border-color: $disabled_text;
  }

  &:focus, &:active,
  &.ant-input-affix-wrapper:focus, &.ant-input-affix-wrapper:active,
  &.ant-input-affix-wrapper:hover:focus-within {
    border-color: #FF803C;
  }

  &:disabled {
    color: $main;
    border-color: $stroke;
    background-color: $disabled_bg;
    &::placeholder {
      color: $placeholder;
    }
  }
  input {
    border-radius: 0;
  }
  .ant-input-suffix {
    margin-left: 16px;
  }

  &__error-message {
    margin: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-wrapper {
    width: 100%;
    padding-bottom: 16px;
    position: relative;

    &.error {
      .input-default {
        border-color: $error;
        &__error-message {
          color: $error;
        }
      }
    }

  .ant-form-item-explain-error {
    margin-top: 4px;
    font-size: 12px;
    line-height: 16px;
  }

    .ant-input-affix-wrapper-focused {
      .ant-input-prefix {
        path {
          fill: $accent_70;
        }
      }
    }

    .typography-label {
      margin-bottom: 8px;
      color: $main;

      span {
        color: $error;
      }
    }
    .ant-input-prefix {
      margin-right: 8px;
    }
  }
}