.candidate-blacklist {
  &__name-local {
    margin-top: 4px;
    &:empty {
      display: none;
    }
  }
  &__warning {
    margin: 24px 0;
  }
}