@import "src/styles/colors";

.ssc-statistics {
  margin-bottom: 24px;

  .ant-table-row:nth-child(1) .ant-table-cell {
    box-shadow: none;
  }

  .ant-table-row:nth-child(2) .ant-table-cell {
    color: $placeholder;
  }

  .ant-table-row:last-child .ant-table-cell {
    padding-top: 46px;
    position: relative;

    &:before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      height: 34px;
      width: 100%;
      background-color: $white;
    }

    &:after {
      content: " ";
      position: absolute;
      top: 34px;
      left: 0;
      height: 1px;
      width: 100%;
      background-color: $border;
    }
  }
}