@import "src/styles/colors";
@import "src/styles/breakpoints";

.candidates-filters {
  margin-right: 32px;
  padding: 24px;
  width: 360px;
  height: min-content;

  &__header {
    display: none;
    height: 64px;
    width: calc(100% + 48px);
    padding: 0 24px;
    align-items: center;
    justify-content: space-between;
    transform: translate(-24px, -24px);
    box-shadow: 0 -1px 0 0 #E1E7F0 inset;

    .open-details {
      svg {
        transform: rotate(-90deg);
        path {
          stroke: $accent_70;
        }
      }

      &.open svg {
        transform: rotate(-270deg);
      }
    }
  }

  &__show-more.button-default {
    padding: 0;
    height: 20px;
    background-color: $white !important;
    border-color: transparent !important;
    &:hover span {
      color: $accent_80;
    }
    &:focus span {
      color: #FF611E;
    }
    &:active span {
      color: $accent_90;
    }
  }

  &__footer {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;

    .button-default {
      flex: 1;
      &:last-child {
        margin-left: 16px;
      }
    }
  }

  .hidden_field {
    display: none;
  }

  @media only screen and (max-width: $screen-tablet-max) {
    &:not(.open) {
      height: 64px;
      .candidates-filters {
        &__header {
          box-shadow: none;
        }
        &__body {
          display: none;
        }
      }
    }
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
    margin-right: 0;
    width: 100%;

    &__header {
      display: flex;
    }

    &__footer {
      justify-content: flex-end;

      .button-default {
        flex: none;
      }
    }
  }

  @media only screen and (max-width: $screen-mobile-max) {
    margin-right: 0;
    padding: 16px;
    width: 100%;
    border-radius: 0;

    &__header {
      display: flex;
      padding: 0 15px;
      width: calc(100% + 32px);
      transform: translate(-16px, -16px);
    }
  }
}