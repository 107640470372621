@import "../../styles/colors";
@import "../../styles/breakpoints";

.job-steps {
  &_wrapperInfo{
    display: flex;
    justify-content: space-between;
    .title{
      margin-bottom: 4px;
    }
    .loader-right-panel-steps{
      background: $white;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
      border-radius: 6px;
      margin-bottom: 8px;
      padding: 24px;
      width: 698px;
    }
  }
  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
    &_wrapperInfo {
      flex-direction: column;
    }
  }

  @media only screen and (max-width: $screen-mobile-max) {
    &_wrapperInfo {
      flex-direction: column;
    }
  }
}
