@import "src/styles/colors";
@import "src/styles/breakpoints";

.form-candidate-creation {
  margin-left: 294px;
  width: 850px;
  flex: 0 0 850px;
  padding: 24px 24px 32px;
  position: relative;
  box-shadow: $box_shadow_level_3;

  &__title {
    margin-bottom: 24px;
  }

  &__parsing {
    margin-bottom: 32px;
    .button-default {
      margin-bottom: 16px;
    }
  }
  &__relocation {
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    .typography-label {
      margin-bottom: 8px;
    }
    .ant-checkbox-wrapper {
      margin: 0 0 8px 0;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  &__experience {
    margin: 32px 0 16px;
  }

  &__extracting-popup {
    max-width: 262px;
    .ant-modal-close,
    .ant-modal-footer {
      display: none;
    }
    .ant-modal-body {
      padding: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      overflow: hidden;

      .typography-h3 {
        margin: 20px 0 8px;
      }
    }
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
    margin-left: 0;

    .form-body__tabs {
      display: none;
    }
  }

  @media only screen and (max-width: $screen-mobile-max) {
    margin-left: 0;
    padding: 12px 16px;
  }
}
