@import "../../../styles/colors";

.transition-step {
  width: 100%;
  margin-top: 10px;

  .css-p9uw5q-SkeletonTheme:last-child {
    margin-top: 30px;
  }

  .graphic {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #E6E8EB;
    border-radius: 6px 6px 4px 4px;

    ul {
      width: 20%;
      &:nth-child(2) {
        width: 40%;
      }

      li {
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 24px;
        border-bottom: 1px solid #E6E8EB;
        color: #757575;

        &:last-child {
          border-bottom: none;
        }

        &:first-child {
          background: #F1F3F4;
          font-size: 14px;
          border-bottom: none;
          color: #474747;
          font-weight: 600;
          border-right: 1px solid #E6E8EB;

          svg {
            margin-left: 8px;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
      .transition-block {
        .number {
          margin-right: 24px;
          width: 35px;

          span {
            height: 40px;
            display: flex;
            align-items: center;
          }
        }
        .line-block {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;

          .line {
            height: 40px;
            background: transparent;
            padding-top: 8px;
            padding-bottom: 8px;
            display: flex;

            .orange {
              background: $accent_70;
              height: 100%;
              border-bottom-right-radius: 5px;
              border-top-right-radius: 5px;
            }

            .light-orange {
              background: $accent_20;
              height: 100%;
            }
          }
        }
      }
    }
  }
}