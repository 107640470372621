@import 'src/styles/colors';

.datepicker-default {
  width: 140px;
  height: 36px;
  padding: 7px 7px 7px 9px;

  &:hover {
    border-color: $disabled_text;
  }

  &-wrapper {
    margin-bottom: 16px;

    .typography {
      margin-bottom: 8px;
      color: $main;
    }
  }

  input {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 20px;
    border-radius: 0;
  }
  &.ant-picker-focused {
    border-color: $accent_70;
    box-shadow: none;
  }

  .ant-picker-suffix {
    margin-left: 8px;
  }

  .ant-picker-clear {
    right: -8px;
    opacity: 1;
    height: 36px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: transparent;

    &:before {
      content: "";
      background-color: $white;
      width: 20px;
      height: 20px;
      position: absolute;
      z-index: -1;
      top: 6px;
      left: 8px;
    }


    &:hover {
      circle {
        fill: $stroke;
      }
      path {
        fill: $placeholder;
      }
    }
  }

  &.datepicker-double {
    border: 0;
    padding: 0;
    width: 100%;
    background-color: transparent;

    .ant-picker-active-bar {
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
    }
    .ant-picker-active-bar {
      display: none;
    }
    .ant-picker-range-separator {
      height: 8px;
      width: 8px;
      padding: 0 8px 0 0;
    }
    .ant-picker-input {
      position: relative;
      padding: 7px 42px 7px 10px;
      background-color: $white;
      border: 1px solid $stroke;
      border-radius: $border_radius;
      transition: border-color .3s;

      &:hover {
        border-color: $disabled_text;
      }

      &:focus-within {
        border-color: $accent_70;
      }

      &:after {
        position: absolute;
        right: 7px;
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        background-image: url("../../../src/dist/icons/calendar.svg");
        pointer-events: none;
      }
    }

    .ant-picker-clear {
      right: 0;
    }
  }

  &__calendar {
    .ant-picker-decade-panel,
    .ant-picker-year-panel,
    .ant-picker-month-panel,
    .ant-picker-date-panel {
      width: 256px;
    }
    .ant-picker-range-wrapper .ant-picker-panel {
      border: 0;
      &:nth-child(2) {
        display: none;
      }
    }
    .ant-picker-header {
      padding: 8px 16px 0;
      height: 48px;
      border: 0;
      align-items: center;
      justify-content: space-between;
      &-view {
        width: 100%;
        display: flex;
        justify-content: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        button {
          width: auto;
          &:active {
            color: $accent_80;
          }
          &:not(:first-child) {
            margin-left: 12px;
          }
        }
      }
      &-super-prev-btn,
      &-super-next-btn {
        display: none !important;
      }
      &-prev-btn,
      &-next-btn {
        width: 16px;
        height: 16px;
        min-width: unset;
        visibility: visible !important;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: inherit;
          height: inherit;
          path {
            stroke: $disabled_text;
          }
        }
        &:hover path {
          stroke: $main;
        }
        .rotate {
          transform: rotate(180deg);
        }
      }
    }

    .ant-picker-decade-panel .ant-picker-cell {
      font-size: 12px;
    }
    .ant-picker-body {
      padding: 8px 16px 16px;
      width: 256px;
      .ant-picker-content {
        margin-left: -1px;
        width: 225px;
      }
    }

    th {
      &:nth-child(6),
      &:nth-child(7) {
        color: $error;
      }
      line-height: 20px;
    }
    .ant-picker-month-btn,
    .ant-picker-year-btn {
      color: $main;
    }

    .ant-picker-cell {

      padding: 0;
      border: 1px solid white;
      //border-right: 2px solid white;
      //border-bottom: 1px solid white;
      //border-left: 2px solid white;
      line-height: 31px;
      &:before, &:after, &-inner:before, &-inner:after {
        left: -0.5px !important;
        right: -0.5px !important;
        height: 30px !important;
      }

      &-disabled {
        color: rgba(0, 0, 0, 0.25);
        &:before {
          background-color: rgba(0, 0, 0, 0.04);
        }
      }

      &-selected .ant-picker-cell-inner:hover { // current date hover single
        background-color: $accent_80;
        &:before {
          border: 0;
        }
      }

      &-range-hover, &-range-hover-start, &-range-hover-end { // current date range
        &:before, &:after {
          border-radius: 0 !important;
          background-color: $accent_30;
          border: 0 !important;
        }
        &:hover {
          &:before, &:after {
            background-color: $accent_40;
          }
          &:not(.ant-picker-cell-in-view) .ant-picker-cell-inner {
            background-color: transparent;
          }
        }
      }

      &-range-start, &-range-end {
        &:hover {
          .ant-picker-cell-inner {
            background-color: $accent_80;
          }
        }
      }

      &-range-start {
        color: $white;
        &:before {
          background-color: $accent_30;
          border-radius: 4px 0 0 4px;
          left: 0.5px !important;
        }
        &.ant-picker-cell-range-end {
          border-radius: $border_radius;
          .ant-picker-cell-inner {
            border-radius: $border_radius;
            &:before {
              border: none;
            }
          }
        }
        .ant-picker-cell-inner {
          background-color: $accent_70; // first date in period out of month
          border-radius: 4px 0 0 4px;
          color: $white;
        }
      }
      &-range-end {
        &:before {
          background-color: $accent_30 !important;
          border-radius: 0 4px 4px 0;
          right: 0.5px !important;
        }
        .ant-picker-cell-inner {
          background-color: $accent_70; // last date in period out of month
          border-radius: 0 4px 4px 0;
          color: $white;
        }
      }
      &-in-range {
        &:before {
          background-color: $accent_30;
        }

        &.ant-picker-cell-range-hover {
          &:before {
            background-color: $accent_50 !important; // new period above old
          }

          &-start, &-end {
            .ant-picker-cell-inner {
              &, &:after {
                border-radius: 0;
                background-color: #FF803C !important; // new period above old hover
              }
            }
          }
        }
      }
      &-range-start.ant-picker-cell-range-start-near-hover.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-end.ant-picker-cell-selected,
      &-cell-end .ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-start {
        &:before {
          border-radius: 4px 0 0 4px !important;
          background-color: $accent_70;
        }
       }

      &-range-end.ant-picker-cell-range-end-near-hover.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-end.ant-picker-cell-selected,
      &-range-end.ant-picker-cell-range-hover-end.ant-picker-cell-range-hover-edge-start {
        &:before {
          border-radius: 0 4px 4px 0 !important;
          background-color: $accent_70;
        }
      }

      &-inner:after {
        right: 0 !important;
      }
    }

    .ant-picker-month-panel,
    .ant-picker-year-panel {
      .ant-picker-cell {
        height: 30px;

        &-range-start,
        &-range-end {
          background-color: $white;
        }

        &-inner {
          width: 100%;
        }
      }
    }
  }
}
