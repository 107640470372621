.rejection {
  width: 100%;
  margin-top: 10px;
  padding: 25px 24px 32px;

  &.shadow {
    padding-left: 0;
    padding-bottom: 0;

    &.loading {
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 32px;
    }
  }

  .title {
    margin-left: 24px;
  }

  .graphic {
    margin-top: 32px;
    height: 350px;
  }

  .css-p9uw5q-SkeletonTheme {
    width: 100%;

    &:nth-child(2) {
      margin-top: 32px;
    }
  }

}