@import "colors";
@import "breakpoints";

* {
  scrollbar-width: thin;
  scrollbar-color: #e1e1e1 $white;
}

html, body, #root {
  height: 100%;
  width: 100%;
  min-width: $screen-mobile-min;
  padding: 0;
  margin: 0;
  background: $whitegray;

  ::-webkit-scrollbar {
    width: 6px;
    height: 8px;
   }
  ::-webkit-scrollbar-thumb {
    border-width: 0;
    border-radius: 6px;
    background-color: #e1e1e1;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #c4c4c4;
  }
  ::-webkit-scrollbar-track {
    border-width:0;
  }
  .popup-overlay {
    overflow: auto;
  }
}

body {
  overflow-x: auto;
  overflow-anchor: none;
  &.modal-open {
    overflow: hidden;
    padding-right: 6px;
    height: 100vh;
  }
}

b, strong {
  font-weight: 600;
}

.app-loading-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  .app-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    svg {
      margin: 0 auto;
      display: block;
    }
    &__text {
      margin-top: 20px;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.12px;
      color: $grey100;
      text-align: center;
    }
  }
}
