@import 'src/styles/colors.scss';

.avatar-label-default {
  display: flex;
  &__avatar {
    margin-right: 12px;
  }
  &__name,  {
    font-size: 14px;
    line-height: 20px;
    color: $main;
  }
  &__name-extra {
    color: $lightgray;
  }
}
