@import "src/styles/colors";

.login-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .login-card {
    width: 556px;
    height: 535px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    background: url("../../dist/img/login-desktop.svg") center top no-repeat $white;

    &__app-name {
      width: 282px;
      height: 32px;
      margin-bottom: 40px;
    }

    &__title {
      margin-bottom: 16px;
      font-size: 16px;
      white-space: pre-wrap;
    }

    &__subtitle {
      margin-bottom: 40px;
      color: $placeholder;
      white-space: pre-wrap;
    }

    button {
      width: max-content;
      height: 40px;
      padding-left: 12px;
      svg {
        width: 24px;
        height: 24px;
      }
      span {
        margin-left: 8px;
      }
    }
  }
}