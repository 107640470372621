@import "src/styles/breakpoints";
@import "src/styles/colors";

.sorting-button {
  padding: 0;
  color: $main;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  span {
    margin: 0;
  }
  svg {
    margin-left: 4px;
    margin-right: 0;
    height: 20px;
    width: 20px;

    &.desc #Ascending {
      fill: $main;
    }
    &.asc #Descending {
      fill: $main;
    }
  }

  &, &:hover, &:active {
    background-color: transparent;
    svg path {
      fill: $lightgray;
    }
  }
}

.timeline {
  &__comment .button-default {
    margin: 8px 0 0 auto;
    width: 160px;
  }
  &__header {
    padding: 12px 24px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $border;

    .title {
      margin-right: 16px;
      color: $blackblue;
      span {
        margin-left: 8px;
        color: $lightgray;
      }
    }

    .ant-form,
    .actions {
      display: flex;
      align-items: center;
      gap: 8px;

      .divider-default {
        margin: 0;
        height: 32px;
      }
    }
  }

  &__body {
    padding: 24px;

    .reminders {
      padding-bottom: 20px;
      border-bottom: 1px solid $border;

      &__title span {
        margin-left: 8px;
        color: $lightgray;
      }

      &__see-all {
        margin: 8px 0 0 40px;
      }
    }

    .rich-area-default__rich-content {
      flex: none;
    }
  }

  &__load-more {
    margin-top: 44px;
  }

  @media only screen and (max-width: $screen-mobile-max) {
    border: 0;
    border-radius: 0;

    &__header {
      height: auto;
      padding: 12px 16px 8px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      .actions {
        width: 100%;
        position: relative;

        .ant-form-horizontal {
          flex-direction: column;
          align-items: flex-start;
          gap: 0;
        }

        .divider-default {
          display: none;
        }

        .button-menu-default {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
    }

    .info-table {
      tr, &__data {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
