@import "src/styles/colors";
@import "src/styles/breakpoints";

.job-header {
  margin-bottom: 16px;
  padding: 24px 24px 44px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;

  .css-p9uw5q-SkeletonTheme {
    width: 100%;
  }

  &__job-name {
    display: flex;
    .typography-h1 {
      color: $blackblue;
    }
    .status-default {
      margin: 4px 32px 0 12px;
    }
  }

  .typography-h3 {
    margin-top: 4px;
    color: $lightgray;
    display: inline-block;
    cursor: pointer;
  }

  &__extra {
    display: flex;
    align-items: center;

    > :nth-child(2) {
      margin-left: 32px;
    }

    > :nth-child(n + 3) {
      margin-left: 16px;
    }

    .button-menu-default {
      margin-left: 16px;
    }
  }

  &__job-team {
    position: relative;

    > .button-default {
      padding: 0;
      height: auto;
      border-color: transparent !important;
      span {
        font-weight: 600;
        color: $main;
      }
      svg {
        margin: 0 0 0 4px;
      }
      &:hover {
        background-color: $white;
        span {
          color: $accent_70;
        }
        svg path {
          fill: $accent_70;
        }
      }
      &:active span {
        color: $accent_80;
      }
      &:focus {
        span {
          color: $accent_70;
        }
        svg path {
          fill: $accent_70;
        }
      }
    }

    &.open {
      .team-list {
        display: block;
      }
      > .button-default {
        span {
          color: $accent_70;
        }
        svg path {
          fill: $accent_70;
        }
      }
    }
    .team-list {
      display: none;
      padding: 8px 0;
      width: 260px;
      position: absolute;
      right: 0;
      top: 100%;
      color: $main;
      text-align: left;
      z-index: 2;
      cursor: default;

      &__label {
        margin: 0 16px;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: $placeholder;
      }

      .user-default {
        width: 100%;
        padding: 8px 16px;
        &:hover {
          background-color: #F5F7FA;
        }
      }

      .recruiter-wrapper {
        position: relative;

        &__delete {
          display: none;
          position: absolute;
          right: 16px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          path {
            fill: $accent_70;
          }
        }
        & > .user-default {
          padding-right: 32px;
        }

        &:hover {
          background-color: #F5F7FA;
          .recruiter-wrapper__delete {
            display: block;
          }
        }
      }
      .assign-recruiter {
        width: 100%;
        border-radius: 0;
      }
    }
  }

  .menu-list {
    z-index: 2;
  }

  &__tabs {
    position: absolute;
    bottom: 0;
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
    margin-bottom: 16px;
  }

  @media only screen and (max-width: $screen-mobile-max) {
    margin-bottom: 12px;
    padding: 24px 16px 52px;
    flex-direction: column;
    box-shadow: none;
    border-radius: 0;

    &__job {
      width: 100%;
    }

    &__extra {
      margin-top: 16px;
      width: 100%;
    }

    &__job-name {
      justify-content: space-between;

      .status-default {
        margin-right: 0;
      }
    }

    &__job-team {
      margin-right: auto;

      .team-list {
        left: 0;
      }
    }

    &__tabs {
      width: calc(100% - 32px);
      overflow: auto;

      .ant-tabs-nav-wrap:before,
      .ant-tabs-nav-wrap:after,
      .ant-tabs-nav-operations {
        display: none !important;
      }
    }
  }
}