.analytics-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 38px;
  padding-bottom: 83px;
  color: #474747;
  font-size: 15px;

  &.shadow {
    height: 38vh;
    justify-content: center;
  }

  .title {
    font-size: 22px;
    font-weight: normal;
    color: #474747;
  }

  img {
    width: 125px;
    height: 125px;
    margin-bottom: 20px;
  }
}