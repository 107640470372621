@import "src/styles/colors";

.popup-create-interview {
  .automatically-message {
    margin: -12px 0 12px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: $lightgray;
  }
}
